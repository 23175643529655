import React from "react";

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  error: string | any;
  adjust: string;
  options: any;
  setFieldValue: any;
  view_only: boolean;
}
const FormSelect = ({
  label,
  placeholder,
  value,
  name,
  error,
  adjust,
  options,
  setFieldValue,
  view_only,
}: InputProps) => {
  return (
    <>
      <div className={adjust}>
        <label className="ms-1 mb-2">{label}</label>
        <select
          value={value}
          name={name}
          disabled={view_only}
          onChange={(e: any) => setFieldValue(`${name}`, e.target.value)}
          className={`form-control rounded ${error ? "border-danger" : ""}`}
        >
          {/* {value === "" && <option value="">{placeholder}</option>} */}
          <option value="">{placeholder}</option>
          {options.map((current_iteration: any) => {
            return (
              <option value={current_iteration.id}>
                {current_iteration.value}
              </option>
            );
          })}
        </select>

        {error && <span className="text-danger">{error}</span>}

        <br />
      </div>
    </>
  );
};

export default FormSelect;
