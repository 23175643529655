import { useState } from "react";
import Header from "../header/header";
import Sidebar from "../sidebar/sidebar";

const AdminLayout = ({ children }: any) => {
  const [toggle, setToggle] = useState(true);
  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <Sidebar toggle={toggle} setToggle={setToggle} />
          <main
            className={
              toggle
                ? "page-content d-flex flex-column flex-row-fluid"
                : "d-flex flex-column flex-row-fluid"
            }
          >
            <Header setToggle={setToggle} toggle={toggle} />
            {children}
          </main>
        </div>
      </div>
    </>
  );
};

export default AdminLayout;
