import CustomRoutes from "shared/routes/customRoutes";
import "./App.css";
import "./assets/css/simplebar.min.css";
import "./assets/css/choices.min.css";
import "./assets/css/style.min.css";
import "./assets/css/bootstrap-icons.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return <>
  <CustomRoutes /> 
  <ToastContainer />
  </>;
}

export default App;
