import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Input from "shared/components/input/input";
import showToast from "shared/components/toast/showToast";
import { resetUser } from "shared/redux/reducers/userSlice";
import { ResetPasswordService } from "shared/services/authService";
import Validator from "shared/utils/validator";
interface ResetPasswordProps {
  handleNavigation: (current: string, next: string) => void;
}

const ResetPassword = ({ handleNavigation }: ResetPasswordProps) => {
  const { user } = useSelector((state: any) => state.ftb);
  const dispatch = useDispatch();

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: {
        email: user?.forgot?.email,
        otp: user?.forgot?.otp,
        password: "",
        password_confirmation: "",
      },
      validationSchema: Validator.resetPasswordSchema,
      onSubmit: (values, { setSubmitting }) => {
        setTimeout(() => {
          // alert(JSON.stringify(values, null, 2));
          // setSubmitting(false);
          ResetPasswordService(values).then(({ data: { status, data, message } }) => {
            if (status) {
              dispatch(resetUser());
              showToast("success", message)
              handleNavigation("password", "welcome");
            } else {
              showToast("error", message);
            }
          })
          .catch((err) => {
            showToast("error", err?.response?.data?.message);
          });
        }, 400);
      },
    });
  return (
    <>
      <h4>New Password!</h4>
      <p className="mb-4 text-muted">Enter your new password.</p>
      <form onSubmit={handleSubmit}>
        <div className="form-floating mb-3">
          <Input
            label="New Password"
            type="text"
            placeholder="type here..."
            value={values.password}
            onChange={handleChange("password")}
            onBlur={handleBlur}
            name="password"
            error={errors.password && touched.password && errors.password}
          />
        </div>
        <div className="form-floating mb-3">
          <Input
            label="Again Password"
            type="text"
            placeholder="type here..."
            value={values.password_confirmation}
            onChange={handleChange("password_confirmation")}
            onBlur={handleBlur}
            name="password_confirmation"
            error={
              errors.password_confirmation &&
              touched.password_confirmation &&
              errors.password_confirmation
            }
          />
        </div>
        <button className="w-100 btn btn-lg btn-primary rounded" type="submit">
          Set Password
        </button>
      </form>
      <hr className="mt-4 mb-3" />
      <p className="text-muted mb-0">
        Remember your password?
        <span
          className="ms-2 text-dark cursor-pointer"
          onClick={() => handleNavigation("verification", "welcome")}
        >
          Sign In
        </span>
      </p>
    </>
  );
};

export default ResetPassword;
