import Logout from "pages/auth/logout";
import Dashboard from "pages/general/dashboard/dashboard";
import FishLog from "pages/general/fishLog";
import FishLogForm from "pages/general/fishLog/fishLogForm";
import FishingPoint from "pages/general/fishingPoint";
import FishingPointForm from "pages/general/fishingPoint/fishingPointForm";
import GroupInvitationRejection from "pages/general/groupInvitationRejection";
import Layer from "pages/general/layers";
import LayerForm from "pages/general/layers/LayerForm";
import Profile from "pages/general/profile";
import ReasonListing from "pages/general/reasonListing";
import ReasonListingForm from "pages/general/reasonListing/reasonListingForm";
import Resource from "pages/general/resource";
import ResourceForm from "pages/general/resource/resourceForm";
import ResourceType from "pages/general/resourceType";
import ResourceTypeForm from "pages/general/resourceType/resourceTypeForm";
import Route from "pages/general/route";
import RouteForm from "pages/general/route/routeForm";
import SavePoint from "pages/general/savePoint";
import SavePointForm from "pages/general/savePoint/savePointForm";
import Security from "pages/general/security";
import Term from "pages/general/term";
import Trolling from "pages/general/trolling";
import TrollingForm from "pages/general/trolling/trolllingForm";
import Users from "pages/general/users";
import UserForm from "pages/general/users/userForm";
import Test from "pages/test";
import Auth from "shared/layout/auth/auth";

const routeConstant = {
  public: {
    login: {
      path: "/",
      title: "Login",
      component: <Auth />,
    },
  },
  private: {
    dashboard: {
      path: "/dashboard",
      title: "Dashboard",
      component: <Dashboard />,
    },

    // Start Resource Type
    resourceType: {
      path: "/resource-type",
      title: "Resource Type",
      component: <ResourceType />,
    },
    addResourceType: {
      path: "/add-resource-type",
      title: "Add Resource Type",
      component: <ResourceTypeForm />,
    },
    editResourceType: {
      path: "/edit-resource-type/:id",
      title: "Update Resource Type",
      component: <ResourceTypeForm />,
    },
    viewResourceType: {
      path: "/view-resource-type/:id",
      title: "View Resource Type",
      component: <ResourceTypeForm />,
    },
    // End Resource Type

    // Start Resource
    resource: {
      path: "/resource",
      title: "Resource",
      component: <Resource />,
    },
    addResource: {
      path: "/add-resource",
      title: "Add Resource",
      component: <ResourceForm />,
    },
    editResource: {
      path: "/edit-resource/:id",
      title: "Update Resource",
      component: <ResourceForm />,
    },
    viewResource: {
      path: "/view-resource/:id",
      title: "View Resource",
      component: <ResourceForm />,
    },
    // Resource Type
    fishLog: {
      path: "/fish-log/:user_id?",
      title: "Fish Log",
      component: <FishLog />,
    },

    viewFishLog: {
      path: "/view-fish-log/:id",
      title: "View Fish Log",
      component: <FishLogForm />,
    },

    fishingPoint: {
      path: "/fishing-point",
      title: "Fishing Point",
      component: <FishingPoint />,
    },

    addFishingPoint: {
      path: "/add-fishing-point",
      title: "Add Fishing Point",
      component: <FishingPointForm />,
    },
    editFishingPoint: {
      path: "/edit-fishing-point/:id",
      title: "Update Fishing Point",
      component: <FishingPointForm />,
    },

    viewFishingPoint: {
      path: "/view-fishing-point/:id",
      title: "View Fishing Point",
      component: <FishingPointForm />,
    },

    savePoint: {
      path: "/save-point/:user_id?",
      title: "Save Point",
      component: <SavePoint />,
    },

    viewSavePoint: {
      path: "/view-save-point/:id",
      title: "View Save Point",
      component: <SavePointForm />,
    },

    trolling: {
      path: "/trolling/:user_id?",
      title: "Trolling",
      component: <Trolling />,
    },

    viewTrolling: {
      path: "/view-trolling/:id",
      title: "View Trolling",
      component: <TrollingForm />,
    },

    route: {
      path: "/route/:user_id?",
      title: "Route",
      component: <Route />,
    },

    viewRoute: {
      path: "/view-route/:id",
      title: "View Route",
      component: <RouteForm />,
    },

    term: {
      path: "/terms",
      title: "Terms & Conditions",
      component: <Term />,
    },

    privacy: {
      path: "/privacy",
      title: "Privacy Policy",
      component: <Term />,
    },

    layer: {
      path: "/layer",
      title: "Layer",
      component: <Layer />,
    },
    users: {
      path: "/users",
      title: "Users",
      component: <Users />,
    },
    viewUser: {
      path: "/view-user/:id",
      title: "View User",
      component: <UserForm />,
    },
    addLayer: {
      path: "/add-layer",
      title: "Add Layer",
      component: <LayerForm />,
    },
    editLayer: {
      path: "/edit-layer/:id",
      title: "Update Layer",
      component: <LayerForm />,
    },
    viewLayer: {
      path: "/view-layer/:id",
      title: "View Layer",
      component: <LayerForm />,
    },
    profile: {
      path: "/profile",
      title: "Profile",
      component: <Profile />,
    },
    security: {
      path: "/security",
      title: "Security",
      component: <Security />,
    },

    reasonListing: {
      path: "/reason-listing",
      title: "Reason Listing",
      component: <ReasonListing />,
    },
    addReasonListing: {
      path: "/add-reason-listing",
      title: "Add Reason Listing",
      component: <ReasonListingForm />,
    },
    editReasonListing: {
      path: "/edit-reason-listing/:id",
      title: "Update Reason Listing",
      component: <ReasonListingForm />,
    },
    viewReasonListing: {
      path: "/view-reason-listing/:id",
      title: "View Reason Listing",
      component: <ReasonListingForm />,
    },

    groupInvitationRejection: {
      path: "/group-report",
      title: "Group Invitation Rejection",
      component: <GroupInvitationRejection />,
    },

    logout: {
      path: "/logout",
      title: "Logout",
      component: <Logout />,
    },
    test: {
      path: "/test",
      title: "Test",
      component: <Test />,
    },
  },
};

export { routeConstant };
