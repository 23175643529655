// const BaseURL = "https://dev.upworkdeveloper.com/new/fish-the-break/api/admin/";
const BaseURL = "https://app.fishthebreak.com/api/admin/";
// const BaseURL = "http://localhost/Coding Pixel/fish-the-break/api/admin/";

const Endpoint = {
  auth: {
    login: "login",
    logout: "logout",
    send_otp: "send-otp",
    verify_otp: "verify-otp-reset",
    reset: "reset-password",
  },
  dashboard: {
    stats: "dashboard/stats",
    recent_registered_user: "dashboard/recent-registered-users",
  },
  profile: {
    profile_get: "admin/get-self-profile",
    profile_update: "admin/update-self-profile",
    security: "admin/change-password",
  },
  resourceType: {
    get: "resource-type/get-all",
    create: "resource-type/save",
    edit: "resource-type/get-by-id",
    update: "resource-type/update",
    delete: "resource-type/delete",
  },
  resource: {
    get: "resource/get-all",
    create: "resource/save",
    edit: "resource/get-by-id",
    update: "resource/update",
    delete: "resource/delete",
  },
  reasonListing: {
    get: "reason-listing/get-all",
    create: "reason-listing/save",
    edit: "reason-listing/get-by-id",
    update: "reason-listing/update",
    delete: "reason-listing/delete",
  },
  groupInvitationRejection: {
    get: "user/lisitng-of-rejection-group-invitation",
  },
  layer: {
    get: "layer/get-all",
    create: "layer/save",
    edit: "layer/get-by-id",
    update: "layer/update",
    delete: "layer/delete",
    sort: "layer/sort",
  },
  user: {
    get: "user/get-all-users",
    edit: "user/get-user-by-id",
  },
  fishLog: {
    get: "user/get-fish-logs",
    edit: "user/get-fish-log-by-id",
  },
  savePoint: {
    get: "user/get-save-points",
    edit: "user/get-save-point-by-id",
  },
  fishingPoint: {
    get: "fishing-points/get-all",
    create: "fishing-points/save",
    edit: "fishing-points/get-by-id",
    update: "fishing-points/update",
    delete: "fishing-points/delete",
  },
  trolling: {
    get: "user/get-trollings",
  },
  trollingPoint: {
    get: "user/get-trolling-by-id",
  },
  route: {
    get: "user/get-route",
  },
  routePoint: {
    get: "user/get-route-by-id",
  },
  term: {
    get: "terms/get-by-type",
    update: "terms/update",
  },
};

export { BaseURL, Endpoint };
