import { useEffect, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import Table from "shared/components/table/table";
import TableRow from "shared/components/table/tableRow";
import showToast from "shared/components/toast/showToast";
import { routeConstant } from "shared/routes/routeConstant";
import { DeleteLayer, GetLayer, SortLayer } from "shared/services/layerService";
import { useDebouncedCallback } from "use-debounce";

interface ItemType {
  id: number;
  name: string;
}

const Layer = () => {
  const table_heading = routeConstant.private.layer.title;

  const [loading, setLoading] = useState(true);
  const [datas, setDatas] = useState<any[]>([]);

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(10);
  const [endpointCall, setEndpointCall] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [sortData, setSortData] = useState();

  const handleSearchChange = useDebouncedCallback((value: any) => {
    setSearchValue(value);
    setCurrentPage(1);
  }, 1000);

  const handlePageChange = (event: any, newPage: any) => {
    setCurrentPage(newPage);
  };

  const datatable_heading = [
    "Layer Name",
    "Self Hosted",
    "Layer Type",
    "Image",
    "Action",
  ];

  const column = ["layer_name", "self_hosted", "layer_type", "image", "action"];

  const column_types: any = {
    self_hosted: "boolean",
    image: "image",
    action: "action",
  };
  const image_base_path_column = {
    image: "base_url",
  };

  const actions = {
    view: routeConstant.private.viewLayer.path.replace("/:id", ""),
    edit: routeConstant.private.editLayer.path.replace("/:id", ""),
    delete: "",
  };

  const deleteAndUpdateData = (id: any) => {
    DeleteLayer(id)
      .then(({ data: { status, message } }) => {
        if (status) {
          showToast("success", message);
          setEndpointCall(!endpointCall);
        } else {
          showToast("error", message);
        }
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    GetLayer(
      `page=${currentPage}&limit=${itemsPerPage}&search_text=${searchValue}`
    )
      .then(({ data: { status, data, message } }) => {
        if (status) {
          // setTotalPages(data.last_page);
          // setFrom(data.from);
          // setTo(data.to);
          // setTotalQuantity(data.total);

          let ids: any = [];
          data.map((data: any) => {
            ids.push(data.id);
          });
          setSortData(ids);
          setDatas(data);
          setLoading(false);
        } else {
          showToast("error", message);
        }
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.message);
      });
  }, [currentPage, itemsPerPage, endpointCall, searchValue]);

  useEffect(() => {
    let ids: any = [];
    datas.map((data: any) => {
      ids.push(data.id);
    });

    const arraysAreEqual = JSON.stringify(ids) === JSON.stringify(sortData);
    if (ids.length > 0 && arraysAreEqual == false) {
      SortLayer({ layers: ids })
        .then(({ data: { status, message } }) => {
          if (status) {
          } else {
            showToast("error", message);
          }
        })
        .catch((err) => {
          showToast("error", err?.response?.data?.message);
        });
    }
  }, [datas]);

  return (
    <>
      <Table
        table_heading={table_heading}
        datatable_heading={datatable_heading}
        add_button_text={routeConstant.private.addLayer.title}
        add_button_url={routeConstant.private.addLayer.path}
        loading={loading}
        search={true}
        setSearchValue={setSearchValue}
        handleSearchChange={handleSearchChange}
      >
        <ReactSortable tag="tbody" list={datas} setList={setDatas}>
          {datas.map((data: any) => {
            return (
              <tr>
                {column.map((col: any) => {
                  return (
                    <td>
                      <TableRow
                        badge_column={"layer_name"}
                        badge_value={"status"}
                        column={col}
                        data={data}
                        column_types={column_types}
                        image_base_path_column={image_base_path_column}
                        actions={actions}
                        delete_service={deleteAndUpdateData}
                      />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </ReactSortable>
      </Table>

      {/* <CustomPagination
        from={from}
        to={to}
        totalPages={totalPages}
        totalQuantity={totalQuantity}
        currentPage={currentPage}
        handler={handlePageChange}
        loading={loading}
      /> */}
    </>
  );
};

export default Layer;
