import { Images } from "assets/index";
import Forgot from "pages/auth/forgot/forgot";
import Login from "pages/auth/login/login";
import ResetPassword from "pages/auth/resetPassword/reset-password";
import SendOTP from "pages/auth/sendOtp/send-otp";
import { useState } from "react";
import styles from "./style.module.scss";
const Auth = () => {
  const [steps, setSteps] = useState<any>({
    welcome: true,
    forgetPassword: false,
    verification: false,
    password: false,
  });

  const handleNavigation = (current: string, next: string) => {
    setSteps({
      ...steps, //clone
      [current]: false,
      [next]: true,
    });
  };

  return (
    <>
      <div className={`d-flex flex-column flex-root ${styles.backgroundCover}`}>
        <div className="page d-flex flex-row flex-column-fluid">
          <main className="page-content overflow-hidden ms-0 d-flex flex-column flex-row-fluid">
            <div className="content p-1 d-flex flex-column-fluid position-relative">
              <div className="container py-4">
                <div className="row align-items-center justify-content-center">
                  <div className="col-md-8 col-lg-5 col-xl-4">
                    <a
                      href="#"
                      className="d-flex position-relative mb-4 z-index-1 align-items-center justify-content-center"
                    >
                      <span className="sidebar-icon size-60 bg-primary text-white rounded-circle p-2">
                        <img
                          src={Images.LogoImg}
                          alt=""
                          className="img-fluid"
                        />
                      </span>
                    </a>
                    {/* Login start */}
                    <div className="card card-body border-0 shadow-lg p-4 rounded">
                      {steps.welcome ? (
                        <Login handleNavigation={handleNavigation} />
                      ) : steps.forgetPassword ? (
                        <Forgot handleNavigation={handleNavigation} />
                      ) : steps.verification ? (
                        <SendOTP handleNavigation={handleNavigation} />
                      ) : steps.password ? (
                        <ResetPassword handleNavigation={handleNavigation} />
                      ) : null}
                    </div>
                    {/* Login end */}
                    {/* Reset password */}

                    {/* Reset password */}
                  </div>
                </div>
              </div>
            </div>

            <footer className="pb-4">
              <div className="container-fluid px-4">
                <span className="d-block lh-sm small text-muted text-end">
                  &copy; 2023. Copyright
                </span>
              </div>
            </footer>
          </main>
        </div>
      </div>
    </>
  );
};

export default Auth;
