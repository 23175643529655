import Lottie from "react-lottie";
import * as animationData from "../../assets/js/Animation.json";

const Test = () => {
  const buttonStyle = {
    display: "block",
    margin: "10px auto",
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className="content p-4 d-flex flex-column-fluid">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-12">
              <div className="card p-3">
                <div>
                  <Lottie options={defaultOptions} height={400} width={400} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Test;
