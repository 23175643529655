import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { routeConstant } from "./routeConstant";
import AdminLayout from "shared/layout/admin/layout/layout";
import NotFound from "pages/404/NotFound";

const CustomRoutes = () => {
  const publicRoutes:any = routeConstant.public;
  const privateRoutes:any = routeConstant.private;

  const { user } = useSelector((state:any) => state.ftb);
  const isLoggedIn = user?.isLoggedIn;

  return (
    <Routes>
      {!isLoggedIn
        ? Object.keys(publicRoutes).map((key, index) => (
            <Route
              key={index}
              path={publicRoutes[key].path}
              element={publicRoutes[key].component}
            />
          ))
        : Object.keys(privateRoutes).map((key, index) => (
            <Route
              key={index}
              path={privateRoutes[key].path}
              element={<AdminLayout>{privateRoutes[key].component}</AdminLayout>}
            />
          ))}
      {isLoggedIn ? null : <Route path="*" element={<Navigate to={routeConstant.public.login.path} />} />}
      {/* <Route path="*" element={<NotFound /> */}
      <Route path="*" element={<Navigate to={routeConstant.private.dashboard.path} />
    
    } />
    </Routes>
  );
};

export default CustomRoutes;
