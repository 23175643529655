import { useMediaQuery } from "@mui/material";
import { Images } from "assets/index";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import * as Icon from "react-feather";
import { Link, useLocation } from "react-router-dom";
import LogoutPopUp from "shared/modals/logout/logout";
import { routeConstant } from "shared/routes/routeConstant";
import styles from "./style.module.scss";

const Sidebar = ({ toggle, setToggle }: any) => {
  const location = useLocation();
  const tabScreen = useMediaQuery("(min-width:992px)");

  const isActiveTab = (path: any) => {
    // return location.pathname === path ? styles.activeTab : "";
    const specific_path =
      path && path.includes(":") ? path.split(":")[0] : path;
    return location.pathname === specific_path ? `text-white bg-primary` : "";
  };

  const [showLogoutPopup, setShowLogoutPopup] = useState<boolean>(false);

  const sidebarRef = useRef(null);
  useOutsideAlert(sidebarRef, tabScreen);

  function useOutsideAlert(ref: any, tab: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (!tab) {
            setToggle(false);
          }
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, tab]);
  }

  useEffect(() => {
    if (tabScreen) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  }, [tabScreen]);

  const navItems = [
    // Dashboard
    {
      title: routeConstant.private.dashboard.title,
      icon: <Icon.Activity className="fe-2x" />,
      path: routeConstant.private.dashboard.path,
    },
    // Resource Type
    {
      title: routeConstant.private.resourceType.title,
      icon: <Icon.Layers className="fe-2x" />,
      path: routeConstant.private.resourceType.path,
    },
    // Resource
    {
      title: routeConstant.private.resource.title,
      icon: <Icon.Layers className="fe-2x" />,
      path: routeConstant.private.resource.path,
    },
    // Layer
    {
      title: routeConstant.private.layer.title,
      icon: <Icon.Activity className="fe-2x" />,
      path: routeConstant.private.layer.path,
    },
    {
      title: routeConstant.private.fishingPoint.title,
      icon: <Icon.MapPin className="fe-2x" />,
      path: routeConstant.private.fishingPoint.path,
    },
    // Reason Listing
    {
      title: routeConstant.private.reasonListing.title,
      icon: <Icon.Clipboard className="fe-2x" />,
      path: routeConstant.private.reasonListing.path,
    },
    // Users
    {
      title: routeConstant.private.users.title,
      icon: <Icon.Users className="fe-2x" />,
      path: routeConstant.private.users.path,
    },
    // Fish Log
    {
      title: routeConstant.private.fishLog.title,
      icon: <Icon.Activity className="fe-2x" />,
      path: routeConstant.private.fishLog.path.replace(":user_id", ""),
    },
    // Save Point
    {
      title: routeConstant.private.savePoint.title,
      icon: <Icon.MapPin className="fe-2x" />,
      path: routeConstant.private.savePoint.path.replace(":user_id", ""),
    },
    // Trolling
    {
      title: routeConstant.private.trolling.title,
      icon: <Icon.Anchor className="fe-2x" />,
      path: routeConstant.private.trolling.path.replace(":user_id", ""),
    },
    // Route
    {
      title: routeConstant.private.route.title,
      icon: <Icon.TrendingUp className="fe-2x" />,
      path: routeConstant.private.route.path.replace(":user_id", ""),
    },

    {
      title: routeConstant.private.groupInvitationRejection.title,
      icon: <Icon.UserX className="fe-2x" />,
      path: routeConstant.private.groupInvitationRejection.path,
    },
    // Privacy Policy
    {
      title: routeConstant.private.privacy.title,
      icon: <Icon.Clipboard className="fe-2x" />,
      path: routeConstant.private.privacy.path,
    },
    // Terms & Conditions
    {
      title: routeConstant.private.term.title,
      icon: <Icon.Clipboard className="fe-2x" />,
      path: routeConstant.private.term.path,
    },
  ];

  return (
    <>
      <aside
        ref={sidebarRef}
        className={classNames(
          toggle
            ? "page-sidebar aside-light bg-light shadow"
            : "page-sidebar d-none bg-light",
          styles.mobileSidebar
        )}
      >
        <div className="h-100 flex-column d-flex" data-simplebar>
          <div className="aside-logo p-3 position-relative">
            <Link
              to={routeConstant.private.dashboard.path}
              className="d-block px-2"
            >
              <div className="d-flex align-items-center flex-no-wrap text-truncate">
                <span className="sidebar-icon fs-5 lh-1 text-white rounded-3 bg-primary fw-bold">
                  <img src={Images.LogoImg} alt="" className="img-fluid p-1" />
                </span>
                <span className="sidebar-text">
                  <span className="sidebar-text text-truncate fs-4 text-uppercase fw-bolder">
                    Fish the Break
                  </span>
                </span>
              </div>
            </Link>
          </div>
          <div
            className={`${styles.flexColumnFluid} aside-menu px-2 my-auto flex-column-fluid`}
          >
            <nav className="flex-grow-1 h-100" id="page-navbar">
              <ul className="nav flex-column collapse-group collapse d-flex pt-4">
                {navItems.map((navItem: any) => {
                  return (
                    <li className="nav-item">
                      <Link
                        to={navItem.path}
                        className={`nav-link d-flex align-items-center text-truncate
                                    ${isActiveTab(navItem.path)}`}
                      >
                        <span className="sidebar-icon">{navItem.icon}</span>
                        <span className="sidebar-text">{navItem.title}</span>
                      </Link>
                    </li>
                  );
                })}
                <li className="nav-item">
                  <LogoutPopUp />
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
