import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { setUser } from "shared/redux/reducers/userSlice";
import LottieLoader from "../LottieLoader";
import FormPassword from "../input/FormPassword";
import FormCheckbox from "../input/formCheckbox";
import FormFile from "../input/formFile";
import FormInput from "../input/formInput";
import FormRadio from "../input/formRadio";
import FormSelect from "../input/formSelect";
import FormSelect2 from "../input/formSelect2";
import FormTextarea from "../input/formTextarea";
import TextEditor from "../input/textEditor";
import showToast from "../toast/showToast";
import LatitudeInput from "../input/latitudeInput";
import LongitudeInput from "../input/longitudeInput";

{
  /*
form_inputs are the form fields
fields_default_values are the default values of the fields
validator is the validation schema
form_heading is the heading of the form
form_save_button_text is the text of the submit button
*/
}
interface FormProps {
  form_inputs?: any;
  // fields_default_values: Record<string, any>;
  fields_default_values: any;
  validator?: Record<string, any>;
  form_heading?: string;
  form_save_button_text?: string;
  view_only?: any;
  create_service?: any;
  update_service?: any;
  form_id?: string;
  redirect_service_success_path?: any;
  redux?: boolean;
  reset?: boolean;
}

const Form: React.FC<FormProps> = ({
  form_inputs,
  fields_default_values,
  validator,
  form_heading,
  form_save_button_text,
  view_only,
  create_service,
  update_service,
  form_id,
  redirect_service_success_path,
  redux,
  reset,
}: FormProps) => {
  const dispatch = useDispatch();

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setSubmitting,
    setFieldValue,
    isSubmitting,
    initialValues,
  } = useFormik({
    initialValues: fields_default_values,
    validationSchema: validator,
    onSubmit: async () => {
      setSubmitting(true);
      handleForm();
    },
  });

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if (
      JSON.stringify(fields_default_values) !== JSON.stringify(initialValues)
    ) {
      Object.entries(fields_default_values).forEach(([key, value]) => {
        setFieldValue(key, value);
      });
      setLoading(false);
    } else {
      // if (!form_id) {
      //   setLoading(false);
      // }
      setLoading(false);
    }
  }, [fields_default_values, initialValues, setFieldValue]);

  const handleForm = () => {
    if (form_id) {
      setLoading(true);

      update_service(form_id, values)
        .then(({ data: { status, data, message } }: any) => {
          if (status) {
            showToast("success", message);
            navigate(redirect_service_success_path);
          } else {
            showToast("error", message);
          }
        })
        .catch((err: any) => {
          showToast("error", err?.response?.data?.message);
        })
        .finally(() => {
          setSubmitting(false);
          setLoading(false);
        });
    } else {
      setLoading(true);

      create_service(values)
        .then(({ data: { status, data, message } }: any) => {
          if (status) {
            if (redux) {
              dispatch(
                setUser({
                  user: data,
                })
              );
            }
            showToast("success", message);
            navigate(redirect_service_success_path);
          } else {
            showToast("error", message);
          }
        })
        .catch((err: any) => {
          showToast("error", err?.response?.data?.message);
        })
        .finally(() => {
          setSubmitting(false);
          setLoading(false);
        });
    }
  };

  const setHandleChange = (key: any, content: any) => {
    if (key && content) {
      setFieldValue(key, content);
    }
  };

  const [latitude, setLatitude] = useState({
    deg: "",
    min: "",
    sec: "",
    dir: "N",
  });
  const [longitude, setLongitude] = useState({
    deg: "",
    min: "",
    sec: "",
    dir: "E",
  });
  const [latitudeError, setLatitudeError] = useState("");
  const [longitudeError, setLongitudeError] = useState("");
  const [errorMessageShow, setErrorMessageShow] = useState(true); // Set to true to show error messages by default

  const handleLatitudeChange = (value: any) => {
    setLatitude(value);
    // Perform validation if needed
    // setLatitudeError(errorMessage) if validation fails
  };

  // Handle changes for longitude input
  const handleLongitudeChange = (value: any) => {
    setLongitude(value);
    // Perform validation if needed
    // setLongitudeError(errorMessage) if validation fails
  };

  return (
    <>
      {loading && (
        <LottieLoader />
        // <div
        //   style={{
        //     position: "fixed",
        //     top: "50%",
        //     left: "50%",
        //     transform: "translate(-50%, -50%)",
        //     zIndex: 9999,
        //     backgroundColor: "#f5f8fb",
        //     borderRadius: "8px",
        //     padding: "20px",
        //   }}
        // >
        //   <Spinner animation="border" role="status" />
        // </div>
      )}
      {!loading && (
        <>
          <form onSubmit={handleSubmit}>
            <div className="toolbar p-4 pb-0">
              <div className="container-fluid px-0">
                <div className="row align-items-center position-relative">
                  <div className="col-md-8 mb-4 mb-md-0">
                    <h3 className="mb-2">{form_heading}</h3>
                  </div>
                  <div className="col-md-4 text-md-end">
                    {view_only ? null : (
                      <button
                        type="submit"
                        className="btn btn-primary btn-sm"
                        disabled={isSubmitting}
                      >
                        {/* <Icon.PlusCircle className="fe-2x me-2" /> */}
                        {isSubmitting ? "Loading" : form_save_button_text}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="content p-4 d-flex flex-column-fluid">
              <div className="container-fluid px-0">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="container mt-4">
                        <div className="row">
                          {form_inputs.map((form_input: any) => {
                            switch (form_input.type) {
                              case "select":
                                return (
                                  <FormSelect
                                    label={form_input.label}
                                    placeholder={form_input.placeholder}
                                    value={values[form_input.name]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                    name={form_input.name}
                                    options={form_input.options}
                                    error={
                                      errors[form_input.name] &&
                                      touched[form_input.name] &&
                                      errors[form_input.name]
                                    }
                                    adjust={form_input.adjust}
                                    view_only={view_only}
                                  />
                                );

                              case "select2":
                                return (
                                  <FormSelect2
                                    label={form_input.label}
                                    placeholder={form_input.placeholder}
                                    value={values[form_input.name]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                    name={form_input.name}
                                    apiUrl={form_input.apiURL}
                                    pickOption={form_input.pickOption}
                                    error={
                                      errors[form_input.name] &&
                                      touched[form_input.name] &&
                                      errors[form_input.name]
                                    }
                                    adjust={form_input.adjust}
                                    view_only={view_only}
                                  />
                                );
                              case "textarea":
                                return (
                                  <FormTextarea
                                    label={form_input.label}
                                    rows={form_input.rows}
                                    placeholder={form_input.placeholder}
                                    value={values[form_input.name]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                    name={form_input.name}
                                    error={
                                      errors[form_input.name] &&
                                      touched[form_input.name] &&
                                      errors[form_input.name]
                                    }
                                    adjust={form_input.adjust}
                                    view_only={view_only}
                                  />
                                );
                              case "checkbox":
                                return (
                                  <FormCheckbox
                                    label={form_input.label}
                                    value={values[form_input.name]}
                                    onBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                    name={form_input.name}
                                    options={form_input.options}
                                    error={
                                      errors[form_input.name] &&
                                      touched[form_input.name] &&
                                      errors[form_input.name]
                                    }
                                    adjust={form_input.adjust}
                                    view_only={view_only}
                                  />
                                );
                              case "radio":
                                return (
                                  <FormRadio
                                    label={form_input.label}
                                    value={values[form_input.name]}
                                    setFieldValue={setFieldValue}
                                    name={form_input.name}
                                    options={form_input.options}
                                    error={
                                      errors[form_input.name] &&
                                      touched[form_input.name] &&
                                      errors[form_input.name]
                                    }
                                    adjust={form_input.adjust}
                                    view_only={view_only}
                                  />
                                );
                              case "file":
                                return (
                                  <FormFile
                                    label={form_input.label}
                                    placeholder={form_input.placeholder}
                                    value={values[form_input.name]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name={form_input.name}
                                    setFieldValue={setFieldValue}
                                    error={
                                      errors[form_input.name] &&
                                      touched[form_input.name] &&
                                      errors[form_input.name]
                                    }
                                    adjust={form_input.adjust}
                                    view_only={view_only}
                                  />
                                );
                              case "texteditor":
                                return (
                                  <TextEditor
                                    label={form_input.label}
                                    placeholder={form_input.placeholder}
                                    value={values[form_input.name]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name={form_input.name}
                                    setFieldValue={setHandleChange}
                                    error={
                                      errors[form_input.name] &&
                                      touched[form_input.name] &&
                                      errors[form_input.name]
                                    }
                                    adjust={form_input.adjust}
                                    view_only={view_only}
                                  />
                                );
                              case "hr":
                                return <hr />;

                              case "button":
                                return (
                                  <div className={form_input.adjust}>
                                    <Link to={values[form_input.name]}>
                                      <button
                                        type="button"
                                        className="btn btn-info mt-6"
                                      >
                                        View
                                      </button>
                                    </Link>
                                  </div>
                                );
                              case "password":
                                return (
                                  <FormPassword
                                    label={form_input.label}
                                    type={form_input.type}
                                    placeholder={form_input.placeholder}
                                    value={values[form_input.name]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name={form_input.name}
                                    error={
                                      errors[form_input.name] &&
                                      touched[form_input.name] &&
                                      errors[form_input.name]
                                    }
                                    adjust={form_input.adjust}
                                    view_only={view_only}
                                  />
                                );

                              case "latitude":
                                return (
                                  <LatitudeInput
                                    latitude={latitude}
                                    onLatitudeChange={handleLatitudeChange}
                                    latitudeError={latitudeError}
                                    errorMessageShow={errorMessageShow}
                                  />
                                );
                                case "longitude":
                                  return (
                                    <LongitudeInput
                                      longitude={longitude}
                                      onLongitudeChange={handleLongitudeChange}
                                      longitudeError={longitudeError}
                                      errorMessageShow={errorMessageShow}
                                    />
                                  );
                              default:
                                return (
                                  <FormInput
                                    label={form_input.label}
                                    type={form_input.type}
                                    placeholder={form_input.placeholder}
                                    value={values[form_input.name]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name={form_input.name}
                                    error={
                                      errors[form_input.name] &&
                                      touched[form_input.name] &&
                                      errors[form_input.name]
                                    }
                                    adjust={form_input.adjust}
                                    view_only={view_only}
                                  />
                                );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default Form;
