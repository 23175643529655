import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";

const CreateReasonListing = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.reasonListing.create, params);
};

const GetReasonListing = (condition_for_pagination: string) => {
  return HTTP_CLIENT.get(
    Endpoint.reasonListing.get + "?" + condition_for_pagination
  );
};

const EditReasonListing = (id: string) => {
  return HTTP_CLIENT.get(`${Endpoint.reasonListing.edit}/${id}`);
};

const UpdateReasonListing = (id: string, params: {}) => {
  return HTTP_CLIENT.post(`${Endpoint.reasonListing.update}/${id}`, params);
};

const DeleteReasonListing = (id: string) => {
  return HTTP_CLIENT.post(`${Endpoint.reasonListing.delete}/${id}`);
};

export {
  CreateReasonListing,
  DeleteReasonListing,
  EditReasonListing,
  GetReasonListing,
  UpdateReasonListing,
};
