import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LottieLoader from "shared/components/LottieLoader";
import Form from "shared/components/form/form";
import showToast from "shared/components/toast/showToast";
import { routeConstant } from "shared/routes/routeConstant";
import { GetTerm, UpdateTerm } from "shared/services/termService";
import Validator from "shared/utils/validator";

const Term = () => {
  const location = useLocation();
  const terms = location.pathname.includes("terms");

  let redirect_back_url = null;
  let selectedType;

  terms
    ? (redirect_back_url = routeConstant.private.term.path)
    : (redirect_back_url = routeConstant.private.privacy.path);
  terms ? (selectedType = "0") : (selectedType = "1");

  const [formHeading, setFormHeading] = useState("");
  const [formSaveButtonText, setFormSaveButtonText] = useState("");
  const [fieldsDefaultValues, setFieldsDefaultValues] = useState({
    description: "",
  });
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const fetchTermData = async () => {
      setLoader(true);
      try {
        const {
          data: { status, data, message },
        } = await GetTerm(terms ? "0" : "1");
        if (status) {
          setFieldsDefaultValues(data);
        } else {
          showToast("error", message);
        }
        setLoader(false);
      } catch (err: any) {
        showToast("error", err?.response?.data?.message);
        setLoader(false);
      }
    };

    fetchTermData();

    if (terms) {
      setFormHeading(routeConstant.private.term.title);
      setFormSaveButtonText(routeConstant.private.term.title);
    } else {
      setFormHeading(routeConstant.private.privacy.title);
      setFormSaveButtonText(routeConstant.private.privacy.title);
    }
  }, [terms]);

  const formInputs = [
    {
      label: "Description",
      type: "texteditor",
      placeholder: "Type here...",
      name: "description",
      adjust: "col-md-12",
    },
  ];

  return (
    <>
      {loader ? (
        <LottieLoader />
      ) : (
        //    <div
        //    style={{
        //      position: "fixed",
        //      top: "50%",
        //      left: "50%",
        //      transform: "translate(-50%, -50%)",
        //      zIndex: 9999,
        //      backgroundColor: "#f5f8fb",
        //      borderRadius: "8px",
        //      padding: "20px",
        //    }}
        //  >
        //    <Spinner animation="border" role="status" />
        //  </div>
        <Form
          form_inputs={formInputs}
          fields_default_values={fieldsDefaultValues}
          validator={Validator.termSchema}
          form_heading={formHeading}
          view_only={false}
          form_save_button_text={formSaveButtonText}
          update_service={UpdateTerm}
          form_id={selectedType}
          redirect_service_success_path={redirect_back_url}
        />
      )}
    </>
  );
};

export default Term;
