import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";

const GetSavePoint = (condition_for_pagination: string) => {
  return HTTP_CLIENT.get(
    Endpoint.savePoint.get + "?" + condition_for_pagination
  );
};

const EditSavePoint = (id: string) => {
  return HTTP_CLIENT.get(`${Endpoint.savePoint.edit}/${id}`);
};

export { EditSavePoint, GetSavePoint };
