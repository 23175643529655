import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LottieLoader from "shared/components/LottieLoader";
import Form from "shared/components/form/form";
import showToast from "shared/components/toast/showToast";
import { routeConstant } from "shared/routes/routeConstant";
import { GetTrollingPoint } from "shared/services/trollingService";

const TrollingForm = () => {
  type FormInput = {
    label: string;
    type: string;
    placeholder: string;
    name: string;
    adjust: string;
  };

  const { id } = useParams();
  const location = useLocation();
  const containsView = location.pathname.includes("view");
  const [formHeading, setFormHeading] = useState("");
  const [formInputs, setFormInputs] = useState<FormInput[]>([]);
  const [loading, setLoading] = useState(true);

  const [fieldsDefaultValues, setFieldsDefaultValues] = useState({
    id: "",
    type: "",
    trolling_name: "",
    distance: "",
    duration: "",
    slowest_speed: "",
    average_speed: "",
    highest_speed: "",
    lowest_water_temp: "",
    average_water_temp: "",
    highest_water_temp: "",
    date: "",
    time: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setLoading(true);

      GetTrollingPoint(id)
        .then(({ data: { status, data, message } }) => {
          if (status) {
            let setupFormdata = [
              {
                label: "ID",
                type: "text",
                placeholder: "ID",
                name: "id",
                adjust: "col-md-6",
              },
              {
                label: "Type",
                type: "text",
                placeholder: "Trolling Type",
                name: "type",
                adjust: "col-md-6",
              },
              {
                label: "Trolling Name",
                type: "text",
                placeholder: "Trolling Name",
                name: "trolling_name",
                adjust: "col-md-6",
              },
              {
                label: "Distance",
                type: "text",
                placeholder: "Distance",
                name: "distance",
                adjust: "col-md-6",
              },
              {
                label: "Duration",
                type: "text",
                placeholder: "Duration",
                name: "duration",
                adjust: "col-md-6",
              },
              {
                label: "Slowest Speed",
                type: "text",
                placeholder: "Slowest Speed",
                name: "slowest_speed",
                adjust: "col-md-6",
              },
              {
                label: "Average Speed",
                type: "text",
                placeholder: "Average Speed",
                name: "average_speed",
                adjust: "col-md-6",
              },
              {
                label: "Highest Speed",
                type: "text",
                placeholder: "Highest Speed",
                name: "highest_speed",
                adjust: "col-md-6",
              },
              {
                label: "Lowest Water Temperature",
                type: "text",
                placeholder: "Lowest Water Temperature",
                name: "lowest_water_temp",
                adjust: "col-md-6",
              },
              {
                label: "Average Water Temperature",
                type: "text",
                placeholder: "Average Water Temperature",
                name: "average_water_temp",
                adjust: "col-md-6",
              },
              {
                label: "Highest Water Temperature",
                type: "text",
                placeholder: "Highest Water Temperature",
                name: "highest_water_temp",
                adjust: "col-md-6",
              },
              {
                label: "Date",
                type: "text",
                placeholder: "Date",
                name: "date",
                adjust: "col-md-6",
              },
              {
                label: "Time",
                type: "text",
                placeholder: "Time",
                name: "time",
                adjust: "col-md-6",
              },
              {
                label: "hr",
                type: "hr",
                placeholder: "hr",
                name: "hr",
                adjust: "col",
              },
            ];
            const mappedFormInputs = data.trolling_point.map(
              (point: any, index: number) => [
                {
                  label: `Latitude ${index + 1}`,
                  type: "text",
                  placeholder: `Latitude ${index + 1}`,
                  name: `latitude_${index + 1}`,
                  adjust: "col-md-6",
                },
                {
                  label: `Longitude ${index + 1}`,
                  type: "text",
                  placeholder: `Longitude ${index + 1}`,
                  name: `longitude_${index + 1}`,
                  adjust: "col-md-6",
                },
                {
                  label: "hr",
                  type: "hr",
                  placeholder: "hr",
                  name: "hr",
                  adjust: "col",
                },
              ]
            );

            const mergedFormInputs = [
              ...setupFormdata,
              ...mappedFormInputs.flat(),
            ];

            setFormInputs(mergedFormInputs);
            const dynamicFields: any = {};
            for (let i = 1; i <= data.trolling_point.length; i++) {
              dynamicFields[`latitude_${i}`] =
                data.trolling_point[i - 1]?.latitude || "";
              dynamicFields[`longitude_${i}`] =
                data.trolling_point[i - 1]?.longitude || "";
            }

            setFieldsDefaultValues({
              ...fieldsDefaultValues,
              id: data?.id || "",
              type: data?.type ? "Shared" : "Self",
              trolling_name: data?.trolling_name || "",
              distance: data?.distance || "",
              duration: data?.duration || "",
              slowest_speed: data?.slowest_speed || "",
              average_speed: data?.average_speed || "",
              highest_speed: data?.highest_speed || "",
              lowest_water_temp: data?.lowest_water_temp || "",
              average_water_temp: data?.average_water_temp || "",
              highest_water_temp: data?.highest_water_temp || "",
              date: data?.date || "",
              time: data?.time || "",
              ...dynamicFields,
            });
          } else {
            showToast("error", message);
            navigate(routeConstant.private.dashboard.path);
          }
        })
        .catch((err) => {
          showToast("error", err?.response?.data?.message);
        })
        .finally(() => {
          setLoading(false);
        });

      setFormHeading(routeConstant.private.viewTrolling.title);
    }
  }, [id, containsView]);

  return (
    <>
      {loading ? (
        <LottieLoader />
      ) : (
        // <div
        //   style={{
        //     position: "fixed",
        //     top: "50%",
        //     left: "50%",
        //     transform: "translate(-50%, -50%)",
        //     zIndex: 9999,
        //     backgroundColor: "#f5f8fb",
        //     borderRadius: "8px",
        //     padding: "20px",
        //   }}
        // >
        //   <Spinner animation="border" role="status" />
        // </div>
        <Form
          form_inputs={formInputs}
          fields_default_values={fieldsDefaultValues}
          form_heading={formHeading}
          view_only={containsView}
        />
      )}
    </>
  );
};

export default TrollingForm;
