import React, { useEffect, useState } from "react";
import { Images } from "assets";

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  error: string | any;
  adjust: string;
  setFieldValue: any;
  value: any;
  view_only: boolean;
}

const FormFile = ({
  label,
  placeholder,
  name,
  error,
  adjust,
  setFieldValue,
  value,
  view_only,
}: InputProps) => {
  const [previewImage, setPreviewImage] = useState<string | undefined>(
    undefined
  );
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target) {
          const result = event.target.result as string;
          setPreviewImage(result); // Set preview image
          setFieldValue(name, file);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (value != null && typeof value === "string" && !previewImage) {
      setPreviewImage(value);
    }
  }, [value]);


  return (
    <div className={adjust}>
      <label className="ms-1 mb-2">{label}</label>
      <input
        type="file"
        placeholder={placeholder}
        name={name}
        disabled={view_only}
        onChange={handleInputChange}
        className={`form-control rounded ${error ? "border-danger" : ""}`}
      />
      {previewImage && !previewImage?.includes("null") ? (
        <img
          src={previewImage}
          alt="Preview"
          style={{ maxWidth: "100px", maxHeight: "100px" }}
        />
      ) : 
      null
      }
      {error && <span className="text-danger">{error}</span>}
      <br />
    </div>
  );
};

export default FormFile;
