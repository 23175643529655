import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";

const CreateFishingPoint = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.fishingPoint.create, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const GetFishingPoint = (condition_for_pagination: string) => {
  return HTTP_CLIENT.get(
    Endpoint.fishingPoint.get + "?" + condition_for_pagination
  );
};

const EditFishingPoint = (id: string) => {
  return HTTP_CLIENT.get(`${Endpoint.fishingPoint.edit}/${id}`);
};

const UpdateFishingPoint = (id: string, params: {}) => {
  return HTTP_CLIENT.post(`${Endpoint.fishingPoint.update}/${id}`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const DeleteFishingPoint = (id: string) => {
  return HTTP_CLIENT.post(`${Endpoint.fishingPoint.delete}/${id}`);
};
export {
  CreateFishingPoint,
  GetFishingPoint,
  EditFishingPoint,
  UpdateFishingPoint,
  DeleteFishingPoint,
};
