import React from "react";

interface LongitudeFormInputProps {
  longitude: {
    deg: string;
    min: string;
    sec: string;
    dir: string;
  };
  onLongitudeChange: (value: {
    deg: string;
    min: string;
    sec: string;
    dir: string;
  }) => void;
  longitudeError: string | any;
  errorMessageShow?: boolean;
}

const LongitudeInput: React.FC<LongitudeFormInputProps> = ({
  longitude,
  onLongitudeChange,
  longitudeError,
  errorMessageShow,
}) => {
  return (
    <div>
      <div className="row mb-3">
        <label htmlFor="longitude">Longitude:</label>
        <br />
        <div className="col" style={{ position: "relative" }}>
          <input
            type="text"
            value={longitude.deg}
            onChange={(e) =>
              onLongitudeChange({ ...longitude, deg: e.target.value })
            }
            className={`form-control rounded ${
              longitudeError ? "border-danger" : ""
            }`}
            placeholder="Degrees"
            pattern="[0-9]{1,3}"
            required
            style={{ marginRight: "10px", paddingRight: "20px" }}
          />
          <span style={{ position: "absolute", top: "0px", right: "5px" }}>
            °
          </span>
        </div>
        <div className="col" style={{ position: "relative" }}>
          <input
            type="text"
            value={longitude.min}
            onChange={(e) =>
              onLongitudeChange({ ...longitude, min: e.target.value })
            }
            className={`form-control rounded ${
              longitudeError ? "border-danger" : ""
            }`}
            placeholder="Minutes"
            pattern="[0-9]{1,2}"
            required
            style={{ marginRight: "10px", paddingRight: "20px" }}
          />
          <span style={{ position: "absolute", top: "0px", right: "5px" }}>
            '
          </span>
        </div>
        <div className="col" style={{ position: "relative" }}>
          <input
            type="text"
            value={longitude.sec}
            onChange={(e) =>
              onLongitudeChange({ ...longitude, sec: e.target.value })
            }
            className={`form-control rounded ${
              longitudeError ? "border-danger" : ""
            }`}
            placeholder="Seconds"
            pattern="[0-9]{1,2}"
            required
            style={{ marginRight: "10px", paddingRight: "20px" }}
          />
          <span style={{ position: "absolute", top: "0px", right: "5px" }}>
            "
          </span>
        </div>
        <div className="col">
          <select
            value={longitude.dir}
            onChange={(e) =>
              onLongitudeChange({ ...longitude, dir: e.target.value })
            }
            className={`form-control rounded ${
              longitudeError ? "border-danger" : ""
            }`}
          >
            <option value="E">E</option>
            <option value="W">W</option>
          </select>
        </div>
      </div>

      {/* Error message */}
      {errorMessageShow && longitudeError && (
        <span className="text-danger">{longitudeError}</span>
      )}
    </div>
  );
};

export default LongitudeInput;
