import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";

const GetUsers = (condition_for_pagination: string) => {
  return HTTP_CLIENT.get(Endpoint.user.get + "?" + condition_for_pagination);
};
const EditUser = (id: string) => {
  return HTTP_CLIENT.get(`${Endpoint.user.edit}/${id}`);
};

export { EditUser, GetUsers };
