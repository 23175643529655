import { useEffect, useState } from "react";
import CustomPagination from "shared/components/pagination";
import Table from "shared/components/table/table";
import TableRow from "shared/components/table/tableRow";
import showToast from "shared/components/toast/showToast";
import { routeConstant } from "shared/routes/routeConstant";
import { GetUsers } from "shared/services/userService";
import { useDebouncedCallback } from "use-debounce";

const Users = () => {
  const table_heading = routeConstant.private.users.title;

  const [loading, setLoading] = useState(true);
  const [datas, setDatas] = useState<any[]>([]);

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(10);
  const [endpointCall, setEndpointCall] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = useDebouncedCallback((value: any) => {
    setSearchValue(value);
    setCurrentPage(1);
  }, 1000);

  const handlePageChange = (event: any, newPage: any) => {
    setCurrentPage(newPage);
  };

  const datatable_heading = [
    "First Name",
    "Last Name",
    "Purchase Status",
    "Profile Pic",
    "Email",
    "Action",
  ];

  const column = ["first_name", "last_name", "purchase_status", "profile_pic", "email", "action"];

  const column_types: any = {
    profile_pic: "image",
    action: "action",
  };
  const image_base_path_column = {
    profile_pic: "base_url",
  };

  const actions = {
    view: routeConstant.private.viewUser.path.replace("/:id", ""),
    fishLog: "",
    savePoint: "",
    trolling: "",
    route: "",
  };

  useEffect(() => {
    GetUsers(
      `page=${currentPage}&limit=${itemsPerPage}&search_text=${searchValue}`
    )
      .then(({ data: { status, data, message } }) => {
        if (status) {
          setTotalPages(data.last_page);
          setFrom(data.from);
          setTo(data.to);
          setTotalQuantity(data.total);
          setDatas(data.data);
          setLoading(false);
        } else {
          showToast("error", message);
        }
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.message);
      });
  }, [currentPage, itemsPerPage, endpointCall, searchValue]);

  return (
    <>
      <Table
        table_heading={table_heading}
        datatable_heading={datatable_heading}
        // add_button_text="Add User"
        // add_button_url="/add-user"
        loading={loading}
        setItemsPerPage={setItemsPerPage}
        itemsPerpage={itemsPerPage}
        search={true}
        setSearchValue={setSearchValue}
        handleSearchChange={handleSearchChange}
      >
        <tbody>
          {datas.map((data: any) => {
            return (
              <tr>
                {column.map((col: any) => {
                  return (
                    <td>
                      <TableRow
                        column={col}
                        data={data}
                        column_types={column_types}
                        image_base_path_column={image_base_path_column}
                        actions={actions}
                      />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <CustomPagination
        from={from}
        to={to}
        totalPages={totalPages}
        totalQuantity={totalQuantity}
        currentPage={currentPage}
        handler={handlePageChange}
        loading={loading}
      />
    </>
  );
};

export default Users;
