import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";

const GetTrolling = (condition_for_pagination: string) => {
  return HTTP_CLIENT.get(
    Endpoint.trolling.get + "?" + condition_for_pagination
  );
};
const GetTrollingPoint = (id: string) => {
  return HTTP_CLIENT.get(`${Endpoint.trollingPoint.get}/${id}`);
};

export { GetTrolling, GetTrollingPoint };
