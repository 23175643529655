import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";

const LoginUser = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.auth.login, params);
};

const LogoutUser = () => {
  return HTTP_CLIENT.post(Endpoint.auth.logout, {});
};

const SendOTPService = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.auth.send_otp, params);
};

const VerifyOTP = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.auth.verify_otp, params);
};

const ResetPasswordService = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.auth.reset, params);
};

export {
  LoginUser,
  LogoutUser,
  ResetPasswordService,
  SendOTPService,
  VerifyOTP,
};
