import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";

const GetRoute = (condition_for_pagination: string) => {
  return HTTP_CLIENT.get(Endpoint.route.get + "?" + condition_for_pagination);
};

const GetRoutePoint = (id: string) => {
  return HTTP_CLIENT.get(`${Endpoint.routePoint.get}/${id}`);
};

export { GetRoute, GetRoutePoint };
