import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Form from "shared/components/form/form";
import showToast from "shared/components/toast/showToast";
import { routeConstant } from "shared/routes/routeConstant";
import { GetRoutePoint } from "shared/services/routeService";

const RouteForm = () => {
  type FormInput = {
    label: string;
    type: string;
    placeholder: string;
    name: string;
    adjust: string;
  };

  const { id } = useParams();
  const location = useLocation();
  const containsView = location.pathname.includes("view");
  const [formHeading, setFormHeading] = useState("");
  const [formInputs, setFormInputs] = useState<FormInput[]>([]);
  const [fieldsDefaultValues, setFieldsDefaultValues] = useState({
    id: "",
    type: "",
    name: "",
    image: "",
    description: "",
    date: "",
    time: "",
  });

  const navigate = useNavigate();

  const getLinkByType = (type: string, id: string) => {
    switch (type) {
      case "FishLog":
        return routeConstant.private.viewFishLog.path.replace(":id", "") + id;
      case "SavePoint":
        return routeConstant.private.viewSavePoint.path.replace(":id", "") + id;
      case "Trolling":
        return routeConstant.private.viewTrolling.path.replace(":id", "") + id;
      case "Route":
        return routeConstant.private.viewRoute.path.replace(":id", "") + id;
      default:
        return "";
    }
  };

  useEffect(() => {
    if (id) {
      GetRoutePoint(id)
        .then(({ data: { status, data, message } }) => {
          if (status) {
            let setupFormdata = [
              {
                label: "ID",
                type: "text",
                placeholder: "ID",
                name: "id",
                adjust: "col-md-6",
              },
              {
                label: "Type",
                type: "text",
                placeholder: "Route Type",
                name: "type",
                adjust: "col-md-6",
              },
              {
                label: "Route Name",
                type: "text",
                placeholder: "Route Name",
                name: "name",
                adjust: "col-md-6",
              },

              {
                label: "Description",
                type: "text",
                placeholder: "Description",
                name: "description",
                adjust: "col-md-6",
              },

              {
                label: "Date",
                type: "text",
                placeholder: "Date",
                name: "date",
                adjust: "col-md-6",
              },
              {
                label: "Time",
                type: "text",
                placeholder: "Time",
                name: "time",
                adjust: "col-md-6",
              },
              {
                label: "Image",
                type: "file",
                placeholder: "Image",
                name: "image",
                adjust: "col-md-6",
              },
              {
                label: "hr",
                type: "hr",
                placeholder: "hr",
                name: "hr",
                adjust: "col",
              },
            ];
            const mappedFormInputs = data.points.map(
              (point: any, index: number) => [
                {
                  label: `Type ${index + 1}`,
                  type: "text",
                  placeholder: `Type ${index + 1}`,
                  name: `type_${index + 1}`,
                  adjust: "col-md-11",
                },
                {
                  label: `Button ${index + 1}`,
                  type: "button",
                  placeholder: `Button ${index + 1}`,
                  name: `button_${index + 1}`,
                  adjust: "col-md-1",
                },
                {
                  label: "hr",
                  type: "hr",
                  placeholder: "hr",
                  name: "hr",
                  adjust: "col",
                },
              ]
            );

            const mergedFormInputs = [
              ...setupFormdata,
              ...mappedFormInputs.flat(),
            ];

            setFormInputs(mergedFormInputs);
            const dynamicFields: any = {};
            for (let i = 1; i <= data.points.length; i++) {
              dynamicFields[`type_${i}`] =
                data.points[i - 1]?.pointable_type.replace(
                  "App\\Models\\",
                  ""
                ) || "";

              const typeWithoutPrefix = data.points[
                i - 1
              ]?.pointable_type.replace("App\\Models\\", "");
              dynamicFields[`button_${i}`] =
                getLinkByType(
                  typeWithoutPrefix,
                  data.points[i - 1]?.pointable_id
                ) || "";
            }

            setFieldsDefaultValues({
              ...fieldsDefaultValues,
              id: data?.id || "",
              type: data?.type ? "Shared" : "Self",
              name: data?.name || "",
              image: data.base_url + data.image || "",
              description: data?.description || "",
              date: data?.date || "",
              time: data?.time || "",
              ...dynamicFields,
            });
          } else {
            showToast("error", message);
            navigate(routeConstant.private.dashboard.path);
          }
        })
        .catch((err) => {
          showToast("error", err?.response?.data?.message);
        });

      setFormHeading(routeConstant.private.viewRoute.title);
    }
  }, [id, containsView]);

  return (
    <Form
      form_inputs={formInputs}
      fields_default_values={fieldsDefaultValues}
      form_heading={formHeading}
      view_only={containsView}
    />
  );
};

export default RouteForm;
