import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import LottieLoader from "../LottieLoader";

interface TableProps {
  table_heading: string;
  datatable_heading: string[];
  children: any;
  add_button_text?: string;
  add_button_url?: string;
  loading?: boolean;
  setItemsPerPage?: (val: number) => void;
  itemsPerpage?: any;
  search?: boolean;
  setSearchValue?: any;
  handleSearchChange?: any;
}

const Table = ({
  table_heading,
  datatable_heading,
  children,
  add_button_text,
  add_button_url,
  loading = false,
  setItemsPerPage,
  itemsPerpage,
  search,
  setSearchValue,
  handleSearchChange,
}: TableProps) => {
  return (
    <>
      {loading && (
        <LottieLoader />
        // <div
        //   style={{
        //     position: "fixed",
        //     top: "50%",
        //     left: "50%",
        //     transform: "translate(-50%, -50%)",
        //     zIndex: 9999,
        //     backgroundColor: "#f5f8fb",
        //     borderRadius: "8px",
        //     padding: "20px",
        //   }}
        // >
        //   <Spinner animation="border" role="status" />
        // </div>
      )}
      {!loading && (
        <>
          <div className="toolbar p-4 pb-0">
            <div className="container-fluid px-0">
              <div className="row align-items-center position-relative">
                <div className="col-md-8 mb-4 mb-md-0">
                  <h3 className="mb-2">{table_heading}</h3>
                </div>
                <div className="col-md-4 text-md-end">
                  {add_button_url && (
                    <Link to={add_button_url}>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <Icon.PlusCircle className="fe-2x me-2" />{" "}
                        {add_button_text}
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {itemsPerpage && (
                <>
                  <span className="ms-5 me-2">Show</span>
                  <FormControl style={{ marginRight: "10px" }}>
                    <Select
                      style={{ height: "30px" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={itemsPerpage}
                      onChange={(e: any) => setItemsPerPage?.(e.target.value)}
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl>
                  <span>entries</span>
                </>
              )}
            </div>

            {search && (
              <div
                className="me-5"
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  label="Search..."
                  variant="outlined"
                  className="mt-2"
                  style={{ width: "400px" }}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    handleSearchChange(e.target.value);
                  }}
                />
              </div>
            )}
          </div>

          <div className="content p-4 d-flex flex-column-fluid">
            <div className="container-fluid px-0">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="table-responsive">
                      <table
                        id="datatable"
                        className="table mt-0 table-striped table-card table-nowrap"
                      >
                        <thead className="text-uppercase small text-muted">
                          <tr>
                            {datatable_heading.map((heading: any) => {
                              return <th>{heading}</th>;
                            })}
                          </tr>
                        </thead>
                        {children}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Table;
