import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Form from "shared/components/form/form";
import showToast from "shared/components/toast/showToast";
import { routeConstant } from "shared/routes/routeConstant";
import {
  CreateFishingPoint,
  UpdateFishingPoint,
} from "shared/services/fishingPointService";
import { EditSavePoint } from "shared/services/savePointService";
import Validator from "shared/utils/validator";

const FishingPointForm = () => {
  const { id } = useParams();
  const location = useLocation();
  const containsView = location.pathname.includes("view");
  const [formHeading, setFormHeading] = useState("");
  const [formSaveButtonText, setFormSaveButtonText] = useState("");
  const [fieldsDefaultValues, setFieldsDefaultValues] = useState({
    id: "",
    image: "",
    point_name: "",
    description: "",
    latitude: "",
    longitude: "",
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      EditSavePoint(id)
        .then(({ data: { status, data, message } }) => {
          if (status) {
            setFieldsDefaultValues({
              id: data.id,
              image: data.image ? data.base_url + data.image : null,
              point_name: data.point_name,
              description: data.description,
              latitude: data.latitude,
              longitude: data.longitude,
            });
          } else {
            showToast("error", message);
            navigate(routeConstant.private.dashboard.path);
          }
        })
        .catch((err) => {
          showToast("error", err?.response?.data?.message);
        });

      setFormHeading(routeConstant.private.editFishingPoint.title);
      setFormSaveButtonText(routeConstant.private.editFishingPoint.title);
      if (containsView) {
        setFormHeading(routeConstant.private.viewFishingPoint.title);
      }
    } else {
      setLoading(false);

      setFieldsDefaultValues({
        id: "",
        image: "",
        point_name: "",
        description: "",
        latitude: "",
        longitude: "",
      });
      setFormHeading(routeConstant.private.addFishingPoint.title);
      setFormSaveButtonText(routeConstant.private.addFishingPoint.title);
    }
  }, [id, containsView]);

  const formInputs = [
    // {
    // label: "ID",
    // type: "text",
    // placeholder: "ID",
    // name: "id",
    // adjust: "col-md-6",
    // },
    // {
    // label: "Type",
    // type: "text",
    // placeholder: "Log Type",
    // name: "type",
    // adjust: "col-md-6",
    // },
    {
      label: "Point Name",
      type: "text",
      placeholder: "Point Name",
      name: "point_name",
      adjust: "col-md-6",
    },
    {
      label: "Image",
      type: "file",
      placeholder: "Image",
      name: "image",
      adjust: "col-md-6",
    },
    {
      label: "Description",
      type: "textarea",
      placeholder: "Description",
      name: "description",
      adjust: "col-md-12",
      rows: 5,
    },
    {
      label: "Latitude",
      // type: "latitude",
      type: "text",
      placeholder: "Latitude",
      name: "latitude",
      adjust: "col-md-6",
    },
    {
      label: "Longitude",
      // type: "longitude",
      type: "text",
      placeholder: "Longitude",
      name: "longitude",
      adjust: "col-md-6",
    },
  ];

  return (
    <Form
      form_inputs={formInputs}
      fields_default_values={fieldsDefaultValues}
      validator={Validator.fishingPointSchema}
      form_save_button_text={formSaveButtonText}
      form_heading={formHeading}
      view_only={containsView}
      form_id={id}
      create_service={CreateFishingPoint}
      update_service={UpdateFishingPoint}
      redirect_service_success_path={routeConstant.private.fishingPoint.path}
    />
  );
};

export default FishingPointForm;
