import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";

const GetDashboardStats = () => {
  return HTTP_CLIENT.get(Endpoint.dashboard.stats);
};

const GetRecentRegisteredUser = () => {
  return HTTP_CLIENT.get(Endpoint.dashboard.recent_registered_user);
};

const UpdatePassword = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.profile.security, params);
};

const GetProfile = () => {
  return HTTP_CLIENT.get(Endpoint.profile.profile_get);
};

const UpdateProfile = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.profile.profile_update, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export {
  GetDashboardStats,
  GetProfile,
  GetRecentRegisteredUser,
  UpdatePassword,
  UpdateProfile,
};
