import { useState } from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import Form from "shared/components/form/form";
import { routeConstant } from "shared/routes/routeConstant";
import { UpdatePassword } from "shared/services/dashboardService";
import Validator from "shared/utils/validator";

const Security = () => {
  const [fieldsDefaultValues, setFieldsDefaultValues] = useState({
    old_password: "",
    password: "",
    new_passwor_confirmation: "",
  });

  const formInputs = [
    {
      label: "Current Password",
      type: "password",
      placeholder: "Current Password",
      name: "old_password",
      adjust: "col-md-12",
    },
    {
      label: "New Password",
      type: "password",
      placeholder: "New Password",
      name: "password",
      adjust: "col-md-12",
    },
    {
      label: "New Password Confirmation",
      type: "password",
      placeholder: "New Password Confirmation",
      name: "password_confirmation",
      adjust: "col-md-12",
    },
  ];
  return (
    <>
      <div className="toolbar p-4 pb-0">
        <div className="container-fluid px-0 h-100">
          <div className="row h-100">
            <div className="col-lg-3">
              <ul className="nav nav-tabs nav-vertical mb-4 h-100">
                <li className="nav-item">
                  <Link
                    to={routeConstant.private.profile.path}
                    className="nav-link px-3"
                  >
                    <Icon.User className="fe-1x align-middle me-2" />
                    General
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={routeConstant.private.security.path}
                    className="nav-link px-3 active"
                  >
                    <Icon.Shield className="fe-1x align-middle me-2" />
                    Security
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-9">
              <Form
                form_inputs={formInputs}
                fields_default_values={fieldsDefaultValues}
                validator={Validator.securitySchema}
                create_service={UpdatePassword}
                form_heading={"Update Password"}
                form_save_button_text={"Update Password"}
                redirect_service_success_path={
                  routeConstant.private.resourceType.path
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Security;
