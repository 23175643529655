import React, { useEffect, useState } from "react";

interface InputProps {
  label: string;
  onBlur: any;
  name: string;
  error: string | any;
  adjust: string;
  options: { id: number; label: string }[];
  setFieldValue: (name: string, value: any) => void;
  value: number[];
  view_only: boolean;
}

const FormCheckbox = ({
  label,
  onBlur,
  name,
  error,
  adjust,
  options,
  setFieldValue,
  value,
  view_only,
}: InputProps) => {
  const [checkedItems, setCheckedItems] = useState<number[]>(value);

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkedId = parseInt(event.target.value, 10);
    const isChecked = event.target.checked;

    if (isChecked) {
      setCheckedItems([...checkedItems, checkedId]);
    } else {
      const updatedCheckedItems = checkedItems.filter(
        (item) => item !== checkedId
      );
      setCheckedItems(updatedCheckedItems);
    }
  };

  useEffect(() => {
    setFieldValue(name, checkedItems);
  }, [checkedItems, name, setFieldValue]);

  return (
    <div className={adjust}>
      <label className="ms-1 mb-2">{label}</label>
      <br />

      {options.map((current_iteration) => (
        <div key={current_iteration.id}>
          <input
            type="checkbox"
            id={current_iteration.id.toString() + "-" + name}
            name={name}
            value={current_iteration.id}
            onBlur={onBlur}
            checked={checkedItems.includes(current_iteration.id)}
            onChange={handleCheck}
            disabled={view_only}
          />
          <label
            className="ms-2"
            htmlFor={current_iteration.id.toString() + "-" + name}
          >
            {current_iteration.label}
          </label>
          <br />
        </div>
      ))}

      {error && <span className="text-danger">{error}</span>}
      <br />
    </div>
  );
};

export default FormCheckbox;
