import { useFormik } from "formik";
import { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import * as Icon from "react-feather";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Input from "shared/components/input/input";
import showToast from "shared/components/toast/showToast";
import { setUser } from "shared/redux/reducers/userSlice";
import { routeConstant } from "shared/routes/routeConstant";
import { LoginUser } from "shared/services/authService";
import Validator from "shared/utils/validator";

interface LoginProps {
  handleNavigation: (current: string, next: string) => void;
}

const Login = ({ handleNavigation }: LoginProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleLogin = () => {
    LoginUser(values)
      .then(({ data: { status, data, message } }) => {
        if (status) {
          showToast("success", message);
          dispatch(
            setUser({
              user: data,
              isLoggedIn: true,
              token: data?.token,
            })
          );
          navigate(routeConstant.private.dashboard.path);
        } else {
          showToast("error", message);
        }
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.message);
      })
      .finally(() => setSubmitting(false));
  };
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setSubmitting,
    isSubmitting,
  } = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: Validator.loginSchema,
    onSubmit: async () => {
      setSubmitting(true);
      handleLogin();
    },
  });

  return (
    <>
      <h4>Welcome Back!</h4>
      {isSubmitting ? (
        <>
          <Spinner animation="border" role="status" />
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <div className="form-floating mb-3">
              <Input
                label="Email address"
                type="email"
                placeholder="name@example.com"
                value={values.email}
                onChange={handleChange("email")}
                onBlur={handleBlur}
                name="email"
                error={errors.email && touched.email && errors.email}
              />
            </div>
            <div className="form-floating mb-3">
              <Input
                label="Password"
                type={showPassword ? "text" : "password"}
                placeholder="type here..."
                value={values.password}
                onChange={handleChange("password")}
                onBlur={handleBlur}
                name="password"
                error={errors.password && touched.password && errors.password}
              />
              <span
                className="mt-7 me-3 position-absolute top-0 end-0 translate-middle-y"
                style={{ cursor: "pointer", zIndex: "999" }}
                onClick={toggleShowPassword}
              >
                {showPassword ? (
                  <Icon.EyeOff size={15} />
                ) : (
                  <Icon.Eye size={15} />
                )}
              </span>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div>
                <span
                  onClick={() => handleNavigation("welcome", "forgetPassword")}
                  className="small text-muted cursor-pointer"
                >
                  Forget Password?
                </span>
              </div>
            </div>

            <button
              className="w-100 btn btn-lg btn-primary rounded"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Loading" : "Sign In"}
            </button>
          </form>
        </>
      )}
    </>
  );
};

export default Login;
