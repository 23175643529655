import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";

const GetGroupInvitationRejection = (condition_for_pagination: string) => {
  return HTTP_CLIENT.get(
    Endpoint.groupInvitationRejection.get + "?" + condition_for_pagination
  );
};


export {
  GetGroupInvitationRejection
};
