import { useEffect, useState } from "react";
import { useParams } from "react-router";
import CustomPagination from "shared/components/pagination";
import Table from "shared/components/table/table";
import TableRow from "shared/components/table/tableRow";
import showToast from "shared/components/toast/showToast";
import { routeConstant } from "shared/routes/routeConstant";
import { GetSavePoint } from "shared/services/savePointService";
import { useDebouncedCallback } from "use-debounce";

const SavePoint = () => {
  const table_heading = routeConstant.private.savePoint.title;

  const [loading, setLoading] = useState(true);
  const [datas, setDatas] = useState<any[]>([]);

  const { user_id } = useParams();

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(10);
  const [endpointCall, setEndpointCall] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = useDebouncedCallback((value: any) => {
    setSearchValue(value);
    setCurrentPage(1);
  }, 1000);

  const handlePageChange = (event: any, newPage: any) => {
    setCurrentPage(newPage);
  };

  const datatable_heading = [
    "Point Name",
    "User",
    "Latitude",
    "Longitude",
    "Action",
  ];

  const column = ["point_name", "user", "latitude", "longitude", "action"];

  const column_types: any = {
    user: "relation",
    action: "action",
  };

  const relation_column = {
    user: "first_name",
  };

  const actions = {
    view: routeConstant.private.viewSavePoint.path.replace("/:id", ""),
  };

  let url;
  useEffect(() => {
    url = `page=${currentPage}&limit=${itemsPerPage}&search_text=${searchValue}`;
    if (user_id) {
      url = `page=${currentPage}&limit=${itemsPerPage}&search_text=${searchValue}&user_id=${user_id}`;
    }
    GetSavePoint(url)
      .then(({ data: { status, data, message } }) => {
        if (status) {
          setTotalPages(data.last_page);
          setFrom(data.from);
          setTo(data.to);
          setTotalQuantity(data.total);
          setDatas(data.data);
          setLoading(false);
        } else {
          showToast("error", message);
        }
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.message);
      });
  }, [currentPage, itemsPerPage, endpointCall, searchValue]);

  return (
    <>
      <Table
        table_heading={table_heading}
        datatable_heading={datatable_heading}
        loading={loading}
        setItemsPerPage={setItemsPerPage}
        itemsPerpage={itemsPerPage}
        search={true}
        setSearchValue={setSearchValue}
        handleSearchChange={handleSearchChange}
      >
        <tbody>
          {datas.map((data: any) => {
            return (
              <tr>
                {column.map((col: any) => {
                  return (
                    <td>
                      <TableRow
                        column={col}
                        data={data}
                        column_types={column_types}
                        relation_column={relation_column}
                        actions={actions}
                      />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <CustomPagination
        from={from}
        to={to}
        totalPages={totalPages}
        totalQuantity={totalQuantity}
        currentPage={currentPage}
        handler={handlePageChange}
        loading={loading}
      />
    </>
  );
};

export default SavePoint;
