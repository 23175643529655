import React, { useState } from "react";
import * as Icon from "react-feather";

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  label: string;
  type: string;
  placeholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  name: string;
  error: string | any;
  adjust: string;
  view_only: boolean;
}

const FormPassword = ({
  label,
  type,
  placeholder,
  value,
  onChange,
  onBlur,
  name,
  error,
  adjust,
  view_only,
}: InputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className={`position-relative ${adjust}`}>
      <label className="ms-1 mb-2">{label}</label>
      <div className="input-group">
        <input
          type={showPassword ? "text" : "password"}
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          disabled={view_only}
          className={`form-control rounded ${error ? "border-danger" : ""}`}
        />
        {type === "password" && (
          <span
            className="mt-4 me-3 position-absolute top-0 end-0 translate-middle-y"
            style={{ cursor: "pointer", zIndex: "999" }}
            onClick={toggleShowPassword}
          >
            {showPassword ? <Icon.EyeOff size={15} /> : <Icon.Eye size={15} />}
          </span>
        )}
      </div>
      {error && <span className="text-danger">{error}</span>}
      <br />
    </div>
  );
};

export default FormPassword;
