import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({
  error,
  placeholder,
  label,
  value,
  name,
  setFieldValue,
  adjust,
  view_only,
}: any) => {
  const [tempValue, setTempValue] = useState(value || "");

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "code-block",
  ];

  useEffect(() => {
    if (!view_only && name && setFieldValue) {
      setFieldValue(name, tempValue);
    }
  }, [tempValue, name]);

  const handleOnChange = (content: any) => {
    if (name) {
      setFieldValue(name, content);
      setTempValue(content);
    }
  };

  return (
    <>
      <div className={`${adjust} mb-4`}>
        <label className="ms-1 mb-2">{label}</label>
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          value={tempValue}
          placeholder={placeholder}
          onChange={(content, delta, source, editor) => {
            handleOnChange(content);
          }}
          // onBlur={onBlur}
          readOnly={view_only}
          className={`${error ? "form-control rounded-3 border-danger" : ""}`}
        />
        {/* <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          value={tempValue}
          placeholder={placeholder}
          onChange={setTempValue}
          onBlur={onBlur}
          readOnly={view_only}
          className={`${error ? "form-control rounded-3 border-danger" : ""}`}
        /> */}
      </div>
    </>
  );
};

export default TextEditor;
