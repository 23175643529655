import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LottieLoader from "shared/components/LottieLoader";
import Form from "shared/components/form/form";
import showToast from "shared/components/toast/showToast";
import { routeConstant } from "shared/routes/routeConstant";
import {
  CreateResource,
  EditResource,
  UpdateResource,
} from "shared/services/resourceService";
import { GetResourceType } from "shared/services/resourceTypeService";
import Validator from "shared/utils/validator";

const ResourceForm = () => {
  const { id } = useParams();
  const location = useLocation();
  const containsView = location.pathname.includes("view");
  const [formHeading, setFormHeading] = useState("");
  const [formSaveButtonText, setFormSaveButtonText] = useState("");
  const [loading, setLoading] = useState(true);

  const [fieldsDefaultValues, setFieldsDefaultValues] = useState({
    title: "",
    category: "",
    short_description: "",
    description: "",
    file: "",
    resource_type_id: "",
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      setLoading(true);

      EditResource(id)
        .then(({ data: { status, data, message } }) => {
          if (status) {
            // setFieldsDefaultValues(data);
            setFieldsDefaultValues({
              title: data.title,
              category: data.category,
              short_description: data.short_description,
              description: data.description,
              file: data.base_url + data.file_name,
              resource_type_id: data.resource_type.type_name,
            });
          } else {
            showToast("error", message);
            navigate(routeConstant.private.dashboard.path);
          }
        })
        .catch((err) => {
          showToast("error", err?.response?.data?.message);
        })
        .finally(() => {
          setLoading(false);
        });

      setFormHeading(routeConstant.private.editResource.title);
      setFormSaveButtonText(routeConstant.private.editResource.title);
      if (containsView) {
        setFormHeading(routeConstant.private.viewResource.title);
      }
    } else {
      setLoading(false);

      setFieldsDefaultValues({
        title: "",
        category: "",
        short_description: "",
        description: "",
        file: "",
        resource_type_id: "",
      });
      setFormHeading(routeConstant.private.addResource.title);
      setFormSaveButtonText(routeConstant.private.addResource.title);
    }
  }, [id, containsView]);

  const formInputs = [
    {
      label: "Title",
      type: "text",
      placeholder: "Title",
      name: "title",
      adjust: "col-md-6",
    },
    {
      label: "Category",
      type: "select",
      placeholder: "Select Category...",
      name: "category",
      adjust: "col-md-6",
      options: [
        {
          id: "Most Useful for Beginner",
          value: "Most Useful for Beginner",
        },
      ],
    },
    {
      label: "Short Description",
      type: "text",
      placeholder: "Short Description",
      name: "short_description",
      adjust: "col-md-12",
    },
    {
      label: "Description",
      type: "texteditor",
      placeholder: "Description....",
      name: "description",
      adjust: "col-md-12",
    },
    {
      label: "Resource Image",
      type: "file",
      placeholder: "Image for Resource",
      name: "file",
      adjust: "col-md-6",
    },
    {
      label: "Resource Type",
      type: "select2",
      placeholder: "Select Resource Type...",
      name: "resource_type_id",
      adjust: "col-md-6",
      apiURL: GetResourceType,
      pickOption: {
        id: "id",
        value: "type_name",
      },
    },
  ];

  return (
    <>
      {loading ? (
        <LottieLoader />
      ) : (
        // <div
        //   style={{
        //     position: "fixed",
        //     top: "50%",
        //     left: "50%",
        //     transform: "translate(-50%, -50%)",
        //     zIndex: 9999,
        //     backgroundColor: "#f5f8fb",
        //     borderRadius: "8px",
        //     padding: "20px",
        //   }}
        // >
        //   <Spinner animation="border" role="status" />
        // </div>
        <Form
          form_inputs={formInputs}
          fields_default_values={fieldsDefaultValues}
          validator={Validator.resourceSchema}
          form_heading={formHeading}
          form_save_button_text={formSaveButtonText}
          view_only={containsView}
          create_service={CreateResource}
          update_service={UpdateResource}
          form_id={id}
          redirect_service_success_path={routeConstant.private.resource.path}
        />
      )}
    </>
  );
};

export default ResourceForm;
