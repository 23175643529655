import React, { useEffect, useState } from "react";
import Select from "react-select";

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  error: string | any;
  adjust: string;
  setFieldValue: any;
  view_only: boolean;
  apiUrl: any;
  pickOption: any;
}

const FormSelect2: React.FC<InputProps> = ({
  label,
  placeholder,
  value,
  name,
  error,
  adjust,
  setFieldValue,
  view_only,
  apiUrl,
  pickOption,
}: InputProps) => {
  const [options, setOptions] = useState<any>([]);
  const [searchText, setSearchText] = useState(value ? value : "");

  useEffect(() => {
    const fetchResourceTypes = async () => {
      try {
        const response = await apiUrl(
          `order_by=${"asc"}&column=${"type_name"}&search_text=${searchText}`
        ); // Invoke the apiUrl function
        const data = response.data;
        const temp = data.data.data.map((item: any) => ({
          value: item[pickOption.id].toString(),
          label: item[pickOption.value],
        }));
        setOptions(temp);
      } catch (error) {
        console.error("Error fetching resource types:", error);
      }
    };

    fetchResourceTypes();
  }, [apiUrl, searchText]);

  useEffect(() => {
    if (options.length === 1) {
      setFieldValue(name, options[0].value);
    }
  }, [options, name, setFieldValue]);

  return (
    <>
      <div className={adjust}>
        <label className="ms-1 mb-2">{label}</label>
        <Select
          value={options.find((option: any) => option.value === value)}
          name={name}
          isDisabled={view_only}
          onChange={(selectedOption: any) =>
            setFieldValue(name, selectedOption ? selectedOption.value : null)
          }
          onInputChange={(inputValue) => setSearchText(inputValue)}
          options={options}
          placeholder={placeholder}
          className={`rounded ${error ? "border-danger" : ""}`}
        />

        {error && <span className="text-danger">{error}</span>}

        <br />
      </div>
    </>
  );
};

export default FormSelect2;
