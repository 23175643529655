import * as Yup from "yup";

const loginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const forgotSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const sendOTPSchema = Yup.object().shape({
  otp: Yup.string()
    .matches(/^\d+$/, "OTP must contain only numbers")
    .length(4, "OTP must be exactly 4 digits")
    .required("OTP is required"),
});

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Password must contain at least 8 characters, including at least one letter, one number, and one special character"
    ),
  password_confirmation: Yup.string()
    .required("Password is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});

const layerSchema = Yup.object().shape({
  layer_name: Yup.string().required("Layer Name is required"),
  layer_calling_name: Yup.string().required("Layer Calling Name is required"),
  layer_calling_url: Yup.string().required("Layer Calling URL is required"),
  short_description: Yup.string().required("Short description is required"),
  description: Yup.string().required("Description is required"),
  // self_hosted: Yup.string().required("Self Hosted is required"),
  // self_hosted: Yup.boolean().required('Self Hosted is required'),
  // portal_item_id: Yup.string().required("Portal Item ID is required"),
  layer_type: Yup.string().required("Layer Type is required"),
  status: Yup.string().required("Status is required"),

  // image: Yup.mixed().required("Image is required"),
  // radio: Yup.string().required("Radio is required"),
  // detail: Yup.string().required("Detail is required"),
});

const resourceTypeSchema = Yup.object().shape({
  type_name: Yup.string().required("Type Name is required"),
});

const resourceSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  category: Yup.string().required("Category is required"),
  short_description: Yup.string().required("Short Description is required"),
  description: Yup.string().required("Description is required"),
  resource_type_id: Yup.string().required("Resource Type is required"),
});

const termSchema = Yup.object().shape({
  description: Yup.string().required("Description is required"),
});

const securitySchema = Yup.object().shape({
  old_password: Yup.string().required("Old Password is required"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Password must contain at least 8 characters, including at least one letter, one number, and one special character"
    ),
  password_confirmation: Yup.string()
    .required("Password is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});
const profileSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string().required("Email address is required"),
});
const fishingPointSchema = Yup.object().shape({
  point_name: Yup.string().required("Point Name is required"),
  latitude: Yup.string()
    .required("Latitude is required"),
    // .matches(
    //   /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}$/,
    //   "Latitude must be a valid number between -90 and 90"
    // ),
    longitude: Yup.string()
    .required("Longitude is required"),
    // .matches(
    //   /^-?((([1-9]|[1-9]\d|1[0-7]\d)(\.\d{1,6})?)|180(\.0{1,6})?)$/,
    //   "Longitude must be a valid number between -180 and 180"
    // ),
  description: Yup.string().required("Description is required"),
  // image: Yup.mixed().required("Image is required"),
});

const reasonListingSchema = Yup.object().shape({
  reason: Yup.string().required("Reason is required"),
});

const Validator = {
  loginSchema,
  forgotSchema,
  sendOTPSchema,
  resetPasswordSchema,
  layerSchema,
  resourceTypeSchema,
  resourceSchema,
  termSchema,
  securitySchema,
  profileSchema,
  fishingPointSchema,
  reasonListingSchema,
};

export default Validator;
