import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { resetUser } from "shared/redux/reducers/userSlice";
import { routeConstant } from "shared/routes/routeConstant";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <button
      onClick={() => {
        dispatch(resetUser());
        navigate(routeConstant.public.login.path);
      }}
    >
      Logout
    </button>
  );
};

export default Logout;
