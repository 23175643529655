import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";

const CreateResource = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.resource.create, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const GetResource = (condition_for_pagination: string) => {
  return HTTP_CLIENT.get(
    Endpoint.resource.get + "?" + condition_for_pagination
  );
};

const EditResource = (id: string) => {
  return HTTP_CLIENT.get(`${Endpoint.resource.edit}/${id}`);
};

const UpdateResource = (id: string, params: {}) => {
  return HTTP_CLIENT.post(`${Endpoint.resource.update}/${id}`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const DeleteResource = (id: string) => {
  return HTTP_CLIENT.post(`${Endpoint.resource.delete}/${id}`);
};

export {
  CreateResource,
  DeleteResource,
  EditResource,
  GetResource,
  UpdateResource,
};
