import React from "react";

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  error: string | any;
  errorMessageShow?: boolean;
}
const Input = ({
  label,
  type,
  placeholder,
  value,
  onChange,
  onBlur,
  name,
  error,
  errorMessageShow,
}: InputProps) => {
  return (
    <>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        className={`form-control rounded ${error ? "border-danger" : ""}`}
      />
      <label>{label}</label>

      {!errorMessageShow
        ? null
        : error && <span className="text-danger">{error}</span>}
    </>
  );
};

export default Input;
