import { useEffect, useState } from "react";
import CustomPagination from "shared/components/pagination";
import Table from "shared/components/table/table";
import TableRow from "shared/components/table/tableRow";
import showToast from "shared/components/toast/showToast";
import { routeConstant } from "shared/routes/routeConstant";
import {
  DeleteReasonListing,
  GetReasonListing,
} from "shared/services/reasonListingService";
import { useDebouncedCallback } from "use-debounce";

const ReasonListing = () => {
  const table_heading = routeConstant.private.reasonListing.title;

  const [loading, setLoading] = useState(true);
  const [datas, setDatas] = useState<any[]>([]);

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(10);
  const [endpointCall, setEndpointCall] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = useDebouncedCallback((value: any) => {
    setSearchValue(value);
    setCurrentPage(1);
  }, 1000);

  const handlePageChange = (event: any, newPage: any) => {
    setCurrentPage(newPage);
  };

  const datatable_heading = ["Reason", "Action"];

  const column = ["reason", "action"];

  const column_types: any = {
    action: "action",
  };

  const actions = {
    view: routeConstant.private.viewReasonListing.path.replace("/:id", ""),
    edit: routeConstant.private.editReasonListing.path.replace("/:id", ""),
    delete: "",
  };

  const deleteAndUpdateData = (id: any) => {
    DeleteReasonListing(id)
      .then(({ data: { status, message } }) => {
        if (status) {
          showToast("success", message);
          setEndpointCall(!endpointCall);
        } else {
          showToast("error", message);
        }
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    GetReasonListing(
      `page=${currentPage}&limit=${itemsPerPage}&search_text=${searchValue}`
    )
      .then(({ data: { status, data, message } }) => {
        if (status) {
          setTotalPages(data.last_page);
          setFrom(data.from);
          setTo(data.to);
          setTotalQuantity(data.total);
          setDatas(data.data);
          setLoading(false);
        } else {
          showToast("error", message);
        }
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.message);
      });
  }, [currentPage, itemsPerPage, endpointCall, searchValue]);

  return (
    <>
      <Table
        table_heading={table_heading}
        datatable_heading={datatable_heading}
        add_button_text={routeConstant.private.addReasonListing.title}
        add_button_url={routeConstant.private.addReasonListing.path}
        loading={loading}
        setItemsPerPage={setItemsPerPage}
        itemsPerpage={itemsPerPage}
        search={true}
        setSearchValue={setSearchValue}
        handleSearchChange={handleSearchChange}
      >
        <tbody>
          {datas.map((data: any) => {
            return (
              <tr>
                {column.map((col: any) => {
                  return (
                    <td>
                      <TableRow
                        column={col}
                        data={data}
                        column_types={column_types}
                        actions={actions}
                        delete_service={deleteAndUpdateData}
                      />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <CustomPagination
        from={from}
        to={to}
        totalPages={totalPages}
        totalQuantity={totalQuantity}
        currentPage={currentPage}
        handler={handlePageChange}
        loading={loading}
      />
    </>
  );
};

export default ReasonListing;
