import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as Icon from "react-feather";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import showToast from "shared/components/toast/showToast";
import { resetUser } from "shared/redux/reducers/userSlice";
import { routeConstant } from "shared/routes/routeConstant";
import { LogoutUser } from "shared/services/authService";
import styles from "./style.module.scss";

const LogoutPopUp = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = () => {
    LogoutUser()
      .then(({ data: { status, data, message } }) => {
        if (status) {
          showToast("success", message);
          setShow(false);
          dispatch(resetUser());
          navigate(routeConstant.public.login.path);
        } else {
          showToast("error", message);
        }
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.message);
      });
  };

  return (
    <>
      <span
        className={`${styles.logoutLi} nav-link d-flex align-items-center text-truncate py-0`}
        onClick={handleShow}
      >
        <span className="sidebar-icon">
          <Icon.Trash className="fe-2x" />
        </span>
        <span className="sidebar-text">
          {routeConstant.private.logout.title}
        </span>
      </span>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{routeConstant.private.logout.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to logout!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            cancel
          </Button>
          <Button variant="danger" onClick={handleSubmit}>
            {routeConstant.private.logout.title}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LogoutPopUp;
