import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";

const CreateLayer = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.layer.create, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const GetLayer = (condition_for_pagination: string) => {
  return HTTP_CLIENT.get(Endpoint.layer.get + "?" + condition_for_pagination);
};

const EditLayer = (id: string) => {
  return HTTP_CLIENT.get(`${Endpoint.layer.edit}/${id}`);
};

const UpdateLayer = (id: string, params: {}) => {
  return HTTP_CLIENT.post(`${Endpoint.layer.update}/${id}`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const DeleteLayer = (id: string) => {
  return HTTP_CLIENT.post(`${Endpoint.layer.delete}/${id}`);
};

const SortLayer = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.layer.sort, params);
};

export {
  CreateLayer,
  DeleteLayer,
  EditLayer,
  GetLayer,
  SortLayer,
  UpdateLayer,
};
