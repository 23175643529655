import { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import LottieLoader from "shared/components/LottieLoader";
import showToast from "shared/components/toast/showToast";
import { routeConstant } from "shared/routes/routeConstant";
import { Images } from "assets";
import styles from "./style.module.scss";

import {
  GetDashboardStats,
  GetRecentRegisteredUser,
} from "shared/services/dashboardService";

const Dashboard = () => {
  const [stats, setStats] = useState({
    total_layers: "0",
    self_hosted_layers: "0",
    other_used_layers: "0",
    total_users: "0",
    registered_users_this_month: "0",
    premium_users: "0",
    trial_users: "0",
    trial_avail_users: "0",
  });

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    GetDashboardStats()
      .then(({ data: { status, data, message } }) => {
        if (status) {
          setStats(data);
        } else {
          showToast("error", message);
        }
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    GetRecentRegisteredUser()
      .then(({ data: { status, data, message } }) => {
        if (status) {
          setUsers(data);
        } else {
          showToast("error", message);
        }
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading ? (
        <LottieLoader />
      ) : (
        <div className="content pb-0 p-4 d-flex flex-column-fluid position-relative">
          <div className="container-fluid px-0">
            {/* stats start */}
            <div className="row row-cols-1 row-cols-lg-4 g-4 mb-4">
              {/* Total users start */}
              <div className="col-mb-4">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <span className="d-block mb-2 text-muted small text-uppercase fw-bold">
                          Total Users
                        </span>
                        <h4 className="mb-0">{stats.total_users}</h4>
                      </div>
                    </div>
                    <div id="spark-sales"></div>
                  </div>
                </div>
              </div>
              {/* Total users end */}

              {/* Registered Users this month start */}
              <div className="col-mb-4">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <span className="d-block mb-2 text-muted small text-uppercase fw-bold">
                          Registered Users this Month
                        </span>
                        <h4 className="mb-0">
                          {stats.registered_users_this_month}
                        </h4>
                      </div>
                    </div>
                    <div id="spark-bounce"></div>
                  </div>
                </div>
              </div>
              {/* Registered Users this month end */}

              {/* Trial start */}
              <div className="col-mb-4">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <span className="d-block mb-2 text-muted small text-uppercase fw-bold">
                          Trial Users
                        </span>
                        <h4 className="mb-0">{stats.trial_users}</h4>
                      </div>
                    </div>
                    <div id="spark-bounce"></div>
                  </div>
                </div>
              </div>
              {/* Trial end */}

              {/* Avail Trial start */}
              <div className="col-mb-4">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <span className="d-block mb-2 text-muted small text-uppercase fw-bold">
                          Users Used Trail (Not subscribed)
                        </span>
                        <h4 className="mb-0">{stats.trial_avail_users}</h4>
                      </div>
                    </div>
                    <div id="spark-bounce"></div>
                  </div>
                </div>
              </div>
              {/* Avail Trial end */}

              {/* Premium Users start */}
              <div className="col-mb-4">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <span className="d-block mb-2 text-muted small text-uppercase fw-bold">
                          Premium Users
                        </span>
                        <h4 className="mb-0">{stats.premium_users}</h4>
                      </div>
                    </div>
                    <div id="spark-bounce"></div>
                  </div>
                </div>
              </div>
              {/* Premium Users end */}

              {/* Total layers start */}
              <div className="col-mb-4">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <span className="d-block mb-2 text-muted small text-uppercase fw-bold">
                          Total Layers
                        </span>
                        <h4 className="mb-0">{stats.total_layers}</h4>
                      </div>
                    </div>
                    <div id="spark-earnings"></div>
                  </div>
                </div>
              </div>
              {/* Total layers end */}

              {/* Self hosted layers start */}
              <div className="col-mb-4">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <span className="d-block mb-2 text-muted small text-uppercase fw-bold">
                          Self Hosted Layers
                        </span>
                        <h4 className="mb-0">{stats.self_hosted_layers}</h4>
                      </div>
                    </div>
                    <div id="spark-earnings"></div>
                  </div>
                </div>
              </div>
              {/* Self hosted layers end */}

              {/* Other layers start */}
              <div className="col-mb-4">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <span className="d-block mb-2 text-muted small text-uppercase fw-bold">
                          Other Used Layers
                        </span>
                        <h4 className="mb-0">{stats.other_used_layers}</h4>
                      </div>
                    </div>
                    <div id="spark-expenses"></div>
                  </div>
                </div>
              </div>
              {/* Other layers end */}
            </div>

            {/* stats end */}

            {/* recent registered users start */}
            <div className="row mt-2">
              <div className="col-md-12 mb-4">
                <div className="card h-100">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="mb-0 me-3">Users</h5>
                    <Link
                      to={routeConstant.private.users.path}
                      className="text-primary"
                    >
                      View All
                    </Link>
                  </div>

                  <div className="card-body pt-0">
                    <ul className="list-group list-group-flush">
                      {users.map((user: any) => {
                        return (
                          <>
                            <li className="list-group-item pt-2">
                              <div className="row align-items-center">
                                <div className="col-auto">
                                  {/* <div className="avatar avatar-status status-online"> */}
                                  <div className="avatar">
                                    <Link
                                      to={`${routeConstant.private.viewUser.path.replace(
                                        "/:id",
                                        ""
                                      )}/${user.id}`}
                                      className="text-primary"
                                    >
                                      {user.profile_pic ? (
                                        <img
                                          src={
                                            user.base_url +
                                            "compress/" +
                                            user.profile_pic
                                          }
                                          className={`rounded-circle ${styles.squareImage}`}
                                          alt="Profile Picture"
                                        />
                                      ) : (
                                        <img
                                          src={Images.imagePlaceholder}
                                          className={`rounded-circle
                                                            ${styles.squareImage}`}
                                          title="No image added"
                                          alt="Profile Picture"
                                        />
                                      )}
                                    </Link>
                                  </div>
                                </div>
                                <div className="col">
                                  <h6 className="mb-0">
                                    <Link
                                      to={`${routeConstant.private.viewUser.path.replace(
                                        "/:id",
                                        ""
                                      )}/${user.id}`}
                                      className="text-primary"
                                    >
                                      {/*
                                                        <Icon.Eye /> */}
                                      {user.first_name + " " + user.last_name}
                                    </Link>
                                  </h6>
                                  <small className="text-muted">
                                    {user.email}
                                  </small>
                                </div>
                                <div className="col-auto">
                                  {/* view */}
                                  <Link
                                    to={`${routeConstant.private.viewUser.path.replace(
                                      "/:id",
                                      ""
                                    )}/${user.id}`}
                                    className="text-primary m-2"
                                  >
                                    <Icon.Eye />
                                  </Link>

                                  {/* fislog */}
                                  <Link
                                    to={`${routeConstant.private.fishLog.path.replace(
                                      "/:user_id?",
                                      ""
                                    )}/${user.id}`}
                                    className="text-primary m-2"
                                  >
                                    <Icon.Activity />
                                  </Link>
                                  {/* save point */}
                                  <Link
                                    to={`${routeConstant.private.savePoint.path.replace(
                                      "/:user_id?",
                                      ""
                                    )}/${user.id}`}
                                    className="text-primary m-2"
                                  >
                                    <Icon.MapPin />
                                  </Link>
                                  {/* trolling */}
                                  <Link
                                    to={`${routeConstant.private.trolling.path.replace(
                                      "/:user_id?",
                                      ""
                                    )}/${user.id}`}
                                    className="text-primary m-2"
                                  >
                                    <Icon.Anchor />
                                  </Link>
                                  {/* route */}
                                  <Link
                                    to={`${routeConstant.private.route.path.replace(
                                      "/:user_id?",
                                      ""
                                    )}/${user.id}`}
                                    className="text-primary m-2"
                                  >
                                    <Icon.TrendingUp />
                                  </Link>
                                </div>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* recent registered users end */}
            {/* <div className="row">
                <div className="col-md-12 mb-4">
                    <div className="position-relative h-100 card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h5 className="mb-0">Recent Transactions</h5>
                            <div className="dropdown">
                                <a href="#" data-bs-toggle="dropdown"
                                    className="d-flex align-items-center text-muted">
                                    <Icon.Filter className="fe-1x align-middle" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-end mt-1">
                                    <a href="#!.html" className="dropdown-item">
                                        <Icon.ArrowDown className="fe-1x align-middle text-success" />
                                        Received
                                    </a>
                                    <a href="#!.html" className="dropdown-item">
                                        <Icon.ArrowUp className="fe-1x align-middle text-danger" />
                                        Sent
                                    </a>
                                    <a href="#!.html" className="dropdown-item">
                                        <Icon.Clock className="fe-1x align-middle text-muted" />
                                        History
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item pt-0">
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 me-4 text-danger">
                                                <Icon.ArrowUp className="fe-1x" />
                                            </div>
                                            <div className="d-flex flex-column">
                                                <h6 className="mb-1 text-dark text-sm">Netflix</h6>
                                                <small className="text-muted">
                                                    24 May 2021, 3:40pm
                                                </small>
                                            </div>
                                        </div>
                                        <div
                                            className="d-flex align-items-center text-danger text-gradient text-sm font-weight-bold ms-auto">
                                            - $49
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 me-4 text-success">
                                                <Icon.ArrowDown className="fe-1x" />
                                            </div>
                                            <div className="d-flex flex-column">
                                                <h6 className="mb-1 text-dark text-sm">Paypal</h6>
                                                <small className="text-muted">
                                                    16 Aug 2021, 12:02am
                                                </small>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center text-success ms-auto">
                                            + $ 12,474
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 me-4 text-danger">
                                                <Icon.ArrowUp className="fe-1x" />
                                            </div>
                                            <div className="d-flex flex-column">
                                                <h6 className="mb-1 text-dark text-sm">
                                                    Visa Card ending **62
                                                </h6>
                                                <small className="text-muted">
                                                    24 May 2021, 3:40pm
                                                </small>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center text-danger ms-auto">
                                            - $ 199
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item pb-0">
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 me-4 text-success">
                                                <Icon.ArrowDown className="fe-1x" />
                                            </div>
                                            <div className="d-flex flex-column">
                                                <h6 className="mb-1 text-dark text-sm">
                                                    Adam Voges
                                                </h6>
                                                <small className="text-muted">
                                                    12 May 2021, 3:40pm
                                                </small>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center text-success ms-auto">
                                            + $ 847
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
