import React from "react";

interface LatitudeFormInputProps {
  latitude: {
    deg: string;
    min: string;
    sec: string;
    dir: string;
  };
  onLatitudeChange: (value: {
    deg: string;
    min: string;
    sec: string;
    dir: string;
  }) => void;
  latitudeError: string | any;
  errorMessageShow?: boolean;
}

const LatitudeInput: React.FC<LatitudeFormInputProps> = ({
  latitude,
  onLatitudeChange,
  latitudeError,
  errorMessageShow,
}) => {
  return (
    <div>
      <div className="row mb-3">
        {/* Latitude Input */}
        <label htmlFor="latitude">Latitude:</label>
        <br />
        <div className="col" style={{ position: "relative" }}>
          <input
            type="text"
            className={`form-control rounded ${
              latitudeError ? "border-danger" : ""
            }`}
            placeholder="Degrees"
            pattern="[0-9]{1,3}"
            required
            value={latitude.deg}
            onChange={(e) =>
              onLatitudeChange({ ...latitude, deg: e.target.value })
            }
            style={{ marginRight: "10px", paddingRight: "20px" }} 
          />
          <span style={{ position: "absolute", top: "0px", right: "5px" }}>
            °
          </span>
        </div>

        <div className="col" style={{ position: "relative" }}>
          <input
            type="text"
            value={latitude.min}
            onChange={(e) =>
              onLatitudeChange({ ...latitude, min: e.target.value })
            }
            className={`form-control rounded ${
              latitudeError ? "border-danger" : ""
            }`}
            placeholder="Minutes"
            pattern="[0-9]{1,2}"
            required
            style={{ marginRight: "10px", paddingRight: "20px" }} 
          />
          <span style={{ position: "absolute", top: "0px", right: "5px" }}>
            '
          </span>
        </div>
        <div className="col" style={{ position: "relative" }}>
          <input
            type="text"
            value={latitude.sec}
            onChange={(e) =>
              onLatitudeChange({ ...latitude, sec: e.target.value })
            }
            className={`form-control rounded ${
              latitudeError ? "border-danger" : ""
            }`}
            placeholder="Seconds"
            pattern="[0-9]{1,2}"
            required
            style={{ marginRight: "10px", paddingRight: "20px" }} 
          />
          <span style={{ position: "absolute", top: "0px", right: "5px" }}>
            "
          </span>
        </div>
        <div className="col">
          <select
            value={latitude.dir}
            onChange={(e) =>
              onLatitudeChange({ ...latitude, dir: e.target.value })
            }
            className={`form-control rounded ${
              latitudeError ? "border-danger" : ""
            }`}
          >
            <option value="N">N</option>
            <option value="S">S</option>
          </select>
        </div>
      </div>

      {/* Error message */}
      {errorMessageShow && latitudeError && (
        <span className="text-danger">{latitudeError}</span>
      )}
    </div>
  );
};

export default LatitudeInput;
