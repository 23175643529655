import React from "react";

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  error: string | any;
  adjust: string;
  setFieldValue: any;
  view_only: boolean;
}
const FormTextarea = ({
  label,
  rows,
  placeholder,
  value,
  name,
  error,
  adjust,
  setFieldValue,
  view_only,
}: InputProps) => {
  return (
    <>
      <div className={adjust}>
        <label className="ms-1 mb-2">{label}</label>
        <textarea
          id="w3review"
          name={name}
          rows={rows}
          cols={6}
          onChange={(e: any) => setFieldValue(`${name}`, e.target.value)}
          placeholder={placeholder}
          value={value}
          disabled={view_only}
          className={`form-control rounded ${error ? "border-danger" : ""}`}
        >
          {/* {value} */}
        </textarea>

        {error && <span className="text-danger">{error}</span>}

        <br />
      </div>
    </>
  );
};

export default FormTextarea;
