import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";

const GetTerm = (type: any) => {
  return HTTP_CLIENT.get(Endpoint.term.get + "/" + type);
};

const UpdateTerm = (type: string, params: {}) => {
  return HTTP_CLIENT.post(`${Endpoint.term.update}/${type}`, params);
};

export { GetTerm, UpdateTerm };
