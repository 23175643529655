import React from "react";

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  error: string | any;
  adjust: string;
  view_only: boolean;
}

const FormInput = ({
  label,
  type,
  placeholder,
  value,
  onChange,
  onBlur,
  name,
  error,
  adjust,
  view_only,
}: InputProps) => {
  return (
    <div className={adjust}>
      <label className="ms-1 mb-2">{label}</label>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        disabled={view_only}
        className={`form-control rounded ${error ? "border-danger" : ""}`}
      />
      {error && <span className="text-danger">{error}</span>}
      <br />
    </div>
  );
};

export default FormInput;
