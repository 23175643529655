import { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import { routeConstant } from "shared/routes/routeConstant";
import styles from "./style.module.scss";
import { Images } from "assets";

const TableRow = ({
  column,
  data,
  column_types,
  actions,
  delete_service,
  relation_column,
  image_base_path_column,
  badge_column,
  badge_value,
}: any) => {
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (id: any) => {
    setShow(true);
    setSelectedId(id);
  };

  const handleSubmit = () => {
    if (selectedId) {
      delete_service(selectedId);
      setShow(false);
    }
  };

  const type = column_types[column];

  const toSentenceCase = (str: string): string => {
    const formattedStr = str.replace(/_/g, " ");
    return (
      formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1).toLowerCase()
    );
  };

  switch (type) {
    case "image":
      return data[column] ? (
        <img
          src={data[image_base_path_column[column]] + data[column]}
          className={`rounded-circle ${styles.squareImage}`}
          alt={toSentenceCase(column)}
        />
      ) : (
        <img
          src={Images.imagePlaceholder}
          className={`rounded-circle ${styles.squareImage}`}
          title="No image added"
          alt={toSentenceCase(column)}
        />
      );
    case "boolean":
      return data[column] ? "True" : "False";
    case "relation":
      return data[column][relation_column[column]];
    case "action":
      return (
        <>
          {actions.hasOwnProperty("view") ? (
            <Link
              to={`${actions.view}/${data.id}`}
              className="text-primary m-2"
            >
              <Icon.Eye />
            </Link>
          ) : null}

          {actions.hasOwnProperty("edit") ? (
            <Link
              to={`${actions.edit}/${data.id}`}
              className="text-warning m-2"
            >
              <Icon.Edit />
            </Link>
          ) : null}

          {actions.hasOwnProperty("delete") ? (
            <>
              <span
                className="text-danger m-2"
                onClick={() => handleShow(data.id)}
              >
                <Icon.Trash />
              </span>

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Delete item</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this!</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleSubmit}>
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          ) : null}

          {/* Custom properties */}

          {actions.hasOwnProperty("fishLog") ? (
            <Link
              to={`${routeConstant.private.fishLog.path.replace(
                ":user_id?",
                ""
              )}${data.id}`}
              className="text-primary m-2"
            >
              <Icon.Activity />
            </Link>
          ) : null}

          {actions.hasOwnProperty("savePoint") ? (
            <Link
              to={`${routeConstant.private.savePoint.path.replace(
                ":user_id?",
                ""
              )}${data.id}`}
              className="text-primary m-2"
            >
              <Icon.MapPin />
            </Link>
          ) : null}

          {actions.hasOwnProperty("trolling") ? (
            <Link
              to={`${routeConstant.private.trolling.path.replace(
                ":user_id?",
                ""
              )}${data.id}`}
              className="text-primary m-2"
            >
              <Icon.Anchor />
            </Link>
          ) : null}

          {actions.hasOwnProperty("route") ? (
            <Link
              to={`${routeConstant.private.route.path.replace(
                ":user_id?",
                ""
              )}${data.id}`}
              className="text-primary m-2"
            >
              <Icon.TrendingUp />
            </Link>
          ) : null}
        </>
      );
    default:
      return (
        <>
          {badge_column && column === badge_column ? (
            <>
              <Icon.MoreVertical
                className={`fe-2x me-2 ${styles.cursorMove}`}
              />
              <span
                className={`${styles.cursorText} btn text-white rounded-pill ${
                  data[badge_value] == 1 ? "bg-success" : "bg-danger"
                }`}
              >
                {data[column]}
              </span>
            </>
          ) : (
            data[column]
          )}
        </>
      );
  }
};

export default TableRow;
