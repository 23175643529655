import { Pagination } from "@mui/material";

const CustomPagination = ({
  from,
  to,
  totalQuantity,
  currentPage,
  totalPages,
  handler,
  loading,
}: any) => {
  const displayFrom = from !== null ? from : 0;
  const displayTo = to !== null ? to : 0;

  return !loading ? (
    <div className="container-fluid d-flex justify-content-between">
      <div className="div ps-5 d-flex align-items-center">
        Showing {displayFrom} to {displayTo} of {totalQuantity} entries
      </div>
      <div className="div d-flex">
        <Pagination
          page={currentPage}
          count={totalPages}
          color="primary"
          onChange={handler}
        />
      </div>
    </div>
  ) : null;
};

export default CustomPagination;
