import { useFormik } from "formik";
import { useState } from "react";
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import showToast from "shared/components/toast/showToast";
import { setUser } from "shared/redux/reducers/userSlice";
import { SendOTPService, VerifyOTP } from "shared/services/authService";
import Validator from "shared/utils/validator";

interface SendOTPProps {
  handleNavigation: (current: string, next: string) => void;
}

const SendOTP = ({ handleNavigation }: SendOTPProps) => {
  const { user } = useSelector((state: any) => state.ftb);
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: { email: user?.forgot?.email, otp: "" },
      validationSchema: Validator.sendOTPSchema,
      onSubmit: (values, { setSubmitting }) => {
        console.log(values);
        setTimeout(() => {
          setSubmitting(false);
          VerifyOTP(values)
            .then(({ data: { status, data, message } }) => {
              if (status) {
                dispatch(
                  setUser({
                    forgot: {
                      email: user?.forgot?.email,
                      otp: values.otp,
                    },
                  })
                );
                showToast("success", message);
                handleNavigation("verification", "password");
              } else {
                showToast("error", message);
              }
            })
            .catch((err) => {
              showToast("error", err?.response?.data?.message);
            });
        }, 400);
      },
    });

  const resendOTP = () => {
    SendOTPService(user.forgot)
      .then(({ data: { status, data, message } }) => {
        if (status) {
          showToast("success", message);
        } else {
          showToast("error", message);
        }
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.message);
      });
  };

  return (
    <>
      <h4>OTP Verification!</h4>
      <p className="mb-4 text-muted">Enter your OTP.</p>
      <form onSubmit={handleSubmit}>
        <div className="form-floating mb-3">
          <OTPInput
            value={values.otp}
            onChange={handleChange("otp")}
            numInputs={4}
            inputStyle={`otp-style form-control rounded ${
              errors?.otp ? "border-danger" : ""
            }`}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />
          {/* {errors?.otp && <span className="text-danger">{errors?.otp}</span>} */}
        </div>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div>
            <span
              className="small text-muted cursor-pointer"
              onClick={resendOTP}
            >
              Resend OTP
            </span>
          </div>
        </div>
        <button className="w-100 btn btn-lg btn-primary rounded" type="submit">
          Verify OTP
        </button>
      </form>
      <hr className="mt-4 mb-3" />
      <p className="text-muted mb-0">
        Remember your password?
        <span
          className="ms-2 text-dark cursor-pointer"
          onClick={() => handleNavigation("otp", "welcome")}
        >
          Sign In
        </span>
      </p>
    </>
  );
};

export default SendOTP;
