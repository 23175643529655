import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LottieLoader from "shared/components/LottieLoader";
import Form from "shared/components/form/form";
import showToast from "shared/components/toast/showToast";
import { routeConstant } from "shared/routes/routeConstant";
import { EditUser } from "shared/services/userService";
import Validator from "shared/utils/validator";

const UserForm = () => {
  const { id } = useParams();
  const location = useLocation();
  const containsView = location.pathname.includes("view");
  const [formHeading, setFormHeading] = useState("");
  const [loading, setLoading] = useState(true);

  const [fieldsDefaultValues, setFieldsDefaultValues] = useState({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    profile_pic: "",
    social_login_id: "",
    social_platform: "",
    is_enabled_notifications: "",
    is_enabled_emails: "",
    is_subscribed: "",
    purchase_status: "",
    expiry_date: "",
    product_name: "",
    product_price: "",
    package_month: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setLoading(true);

      EditUser(id)
        .then(({ data: { status, data, message } }) => {
          if (status) {
            setFieldsDefaultValues({
              id: data.id,
              first_name: data.first_name,
              last_name: data.last_name,
              email: data.email,
              username: data.username,
              profile_pic: data.base_url + data.profile_pic,
              social_login_id: data.social_login_id,
              social_platform: data.social_platform,
              is_enabled_notifications: data.is_enabled_notifications,
              is_enabled_emails: data.is_enabled_emails,
              is_subscribed: data.is_subscribed,
              purchase_status: data.purchase_status,
              expiry_date: data.expiry_date,
              product_name: data.product_name,
              product_price: data.product_price,
              package_month: data.package_month,
            });
          } else {
            showToast("error", message);
            navigate(routeConstant.private.dashboard.path);
          }
        })
        .catch((err) => {
          showToast("error", err?.response?.data?.message);
        })
        .finally(() => {
          setLoading(false);
        });

      setFormHeading(routeConstant.private.viewUser.title);
    }
  }, [id, containsView]);

  const formInputs = [
    {
      label: "ID",
      type: "text",
      placeholder: "ID",
      name: "id",
      adjust: "col-md-6",
    },
    {
      label: "First Name",
      type: "text",
      placeholder: "First Name",
      name: "first_name",
      adjust: "col-md-6",
    },
    {
      label: "Last name",
      type: "text",
      placeholder: "Last Name",
      name: "last_name",
      adjust: "col-md-6",
    },
    {
      label: "Email",
      type: "text",
      placeholder: "Email",
      name: "email",
      adjust: "col-md-6",
    },
    {
      label: "Username",
      type: "text",
      placeholder: "Username",
      name: "username",
      adjust: "col-md-6",
    },
    {
      label: "Profile Picture",
      type: "file",
      placeholder: "Profile picture",
      name: "profile_pic",
      adjust: "col-md-6",
    },
    {
      label: "Social Login Id",
      type: "text",
      placeholder: "Social Login ID",
      name: "social_login_id",
      adjust: "col-md-6",
    },
    {
      label: "Social Platform",
      type: "text",
      placeholder: "Social Platform",
      name: "social_platform",
      adjust: "col-md-6",
    },
    {
      label: "Push Notification",
      type: "radio",
      placeholder: "Push Notification",
      name: "is_enabled_notifications",
      adjust: "col-md-6",
      options: [
        {
          id: 0,
          value: "No",
        },
        {
          id: 1,
          value: "Yes",
        },
      ],
    },
    {
      label: "Email Notification",
      type: "radio",
      placeholder: "Email Notification",
      name: "is_enabled_emails",
      adjust: "col-md-6",
      options: [
        {
          id: 0,
          value: "No",
        },
        {
          id: 1,
          value: "Yes",
        },
      ],
    },
    {
      label: "Subsription",
      type: "radio",
      placeholder: "Subscription",
      name: "is_subscribed",
      adjust: "col-md-6",
      options: [
        {
          id: 0,
          value: "No",
        },
        {
          id: 1,
          value: "Yes",
        },
      ],
    },
    {
      label: "Purchase Status",
      type: "text",
      placeholder: "Purchase Status",
      name: "purchase_status",
      adjust: "col-md-6",
    },
    {
      label: "Expiry Date",
      type: "text",
      placeholder: "Expiry Date",
      name: "expiry_date",
      adjust: "col-md-6",
    },
    {
      label: "Product Name",
      type: "text",
      placeholder: "Product Name",
      name: "product_name",
      adjust: "col-md-6",
    },
    {
      label: "Product Price",
      type: "text",
      placeholder: "Product Price",
      name: "product_price",
      adjust: "col-md-6",
    },
    {
      label: "Package Month",
      type: "text",
      placeholder: "Package Month",
      name: "package_month",
      adjust: "col-md-6",
    },
  ];

  return (
    <>
      {loading ? (
        <LottieLoader />
      ) : (
        // <div
        //   style={{
        //     position: "fixed",
        //     top: "50%",
        //     left: "50%",
        //     transform: "translate(-50%, -50%)",
        //     zIndex: 9999,
        //     backgroundColor: "#f5f8fb",
        //     borderRadius: "8px",
        //     padding: "20px",
        //   }}
        // >
        //   <Spinner animation="border" role="status" />
        // </div>
        <Form
          form_inputs={formInputs}
          fields_default_values={fieldsDefaultValues}
          validator={Validator.resourceSchema}
          form_heading={formHeading}
          view_only={containsView}
          form_id={id}
        />
      )}
    </>
  );
};

export default UserForm;
