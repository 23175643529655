import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";

const CreateResourceType = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.resourceType.create, params);
};

const GetResourceType = (condition_for_pagination: string) => {
  return HTTP_CLIENT.get(
    Endpoint.resourceType.get + "?" + condition_for_pagination
  );
};

const EditResourceType = (id: string) => {
  return HTTP_CLIENT.get(`${Endpoint.resourceType.edit}/${id}`);
};

const UpdateResourceType = (id: string, params: {}) => {
  return HTTP_CLIENT.post(`${Endpoint.resourceType.update}/${id}`, params);
};

const DeleteResourceType = (id: string) => {
  return HTTP_CLIENT.post(`${Endpoint.resourceType.delete}/${id}`);
};

export {
  CreateResourceType,
  DeleteResourceType,
  EditResourceType,
  GetResourceType,
  UpdateResourceType,
};
