
const LogoImg = require("./images/logo.png");
const BackgroundImg = require("./images/background.png");
const avatar01 = require("./images/avatars/01.jpg");
const avatar02 = require("./images/avatars/02.jpg");
const avatar03 = require("./images/avatars/03.jpg");
const avatar04 = require("./images/avatars/04.jpg");
const avatar05 = require("./images/avatars/05.jpg");
const avatar06 = require("./images/avatars/06.jpg");
const avatar07 = require("./images/avatars/07.jpg");
const avatar08 = require("./images/avatars/08.jpg");
const imagePlaceholder = require("./images/placeholderImage.png");


const Images = {
    LogoImg,
    BackgroundImg,
    avatar01,
    avatar02,
    avatar03,
    avatar04,
    avatar05,
    avatar06,
    avatar07,
    avatar08,
    imagePlaceholder
};

const Icons = {
};

const Gifs = {
};

export { Gifs, Icons, Images };
