import { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import Form from "shared/components/form/form";
import { routeConstant } from "shared/routes/routeConstant";
import {
  UpdatePassword,
  UpdateProfile,
} from "shared/services/dashboardService";
import Validator from "shared/utils/validator";

const Profile = () => {
  const [activeSection, setActiveSection] = useState("");
  const queryParams = new URLSearchParams(window.location.search);
  const yourParamExists = queryParams.has("security");

  useEffect(() => {
    if (yourParamExists) {
      setActiveSection("securitySection");
    } else {
      setActiveSection("profileSection");
    }
  }, [yourParamExists]);

  useEffect(() => {
    if (activeSection == "profileSection") {
      setFieldsDefaultValuesForProfile({
        first_name: user.user.first_name,
        last_name: user.user.last_name,
        email: user.user.email,
        profile_pic: user.user.profile_pic
          ? user.user.base_url + user.user.profile_pic
          : null,
      });
    } else {
      setFieldsDefaultValuesForSecurity({
        old_password: "",
        password: "",
        password_confirmation: "",
      });
    }
  }, [activeSection]);

  const handleButtonClick = (section: any) => {
    setActiveSection(section);
  };

  const dispatch = useDispatch();

  const { user } = useSelector((state: any) => state.ftb);

  const [fieldsDefaultValuesForProfile, setFieldsDefaultValuesForProfile] =
    useState({
      first_name: user.user.first_name,
      last_name: user.user.last_name,
      email: user.user.email,
      profile_pic: user.user.profile_pic
        ? user.user.base_url + user.user.profile_pic
        : null,
    });

  // profile Start

  const formInputsForProfile = [
    {
      label: "First Name",
      type: "text",
      placeholder: "First Name",
      name: "first_name",
      adjust: "col-md-6",
    },
    {
      label: "Last Name",
      type: "text",
      placeholder: "Last Name",
      name: "last_name",
      adjust: "col-md-6",
    },
    {
      label: "Email Address",
      type: "text",
      placeholder: "Email Address",
      name: "email",
      adjust: "col-md-6",
    },
    {
      label: "Profile Picture",
      type: "file",
      placeholder: "Profile Picture",
      name: "profile_pic",
      adjust: "col-md-6",
    },
  ];
  // Profile End

  // Security start

  const [fieldsDefaultValuesForSecurity, setFieldsDefaultValuesForSecurity] =
    useState({
      old_password: "",
      password: "",
      password_confirmation: "",
    });

  const formInputsForSecurity = [
    {
      label: "Current Password",
      type: "password",
      placeholder: "Current Password",
      name: "old_password",
      adjust: "col-md-12",
    },
    {
      label: "New Password",
      type: "password",
      placeholder: "New Password",
      name: "password",
      adjust: "col-md-12",
    },
    {
      label: "New Password Confirmation",
      type: "password",
      placeholder: "New Password Confirmation",
      name: "password_confirmation",
      adjust: "col-md-12",
    },
  ];
  // Security End

  return (
    <>
      <div className="toolbar p-4 pb-0">
        <div className="container-fluid px-0 h-100">
          <div className="row h-100">
            <div className="col-lg-12">
              <div className="card mb-4">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <button
                      className={`btn ${
                        activeSection === "profileSection"
                          ? "btn-primary"
                          : "btn-outline-primary"
                      }`}
                      style={{ width: "48%" }}
                      onClick={() => handleButtonClick("profileSection")}
                    >
                      <Icon.User className="fe-1x align-middle me-2" />
                      General
                    </button>
                    <button
                      className={`btn ${
                        activeSection === "securitySection"
                          ? "btn-primary"
                          : "btn-outline-primary"
                      }`}
                      style={{ width: "48%" }}
                      onClick={() => handleButtonClick("securitySection")}
                    >
                      <Icon.Shield className="fe-1x align-middle me-2" />
                      Security
                    </button>
                  </div>

                  {activeSection === "profileSection" && (
                    <div className="container border mt-3 pt-3">
                      <Form
                        form_inputs={formInputsForProfile}
                        fields_default_values={fieldsDefaultValuesForProfile}
                        validator={Validator.profileSchema}
                        create_service={UpdateProfile}
                        form_heading={"Update Profile"}
                        form_save_button_text={"Update Profile"}
                        redux={true}
                        redirect_service_success_path={
                          routeConstant.private.profile.path
                        }
                      />
                    </div>
                  )}

                  {activeSection === "securitySection" && (
                    <div className="container border mt-3 pt-3">
                      <Form
                        form_inputs={formInputsForSecurity}
                        fields_default_values={fieldsDefaultValuesForSecurity}
                        validator={Validator.securitySchema}
                        create_service={UpdatePassword}
                        form_heading={"Update Password"}
                        form_save_button_text={"Update Password"}
                        redirect_service_success_path={
                          routeConstant.private.profile.path + "?security"
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
