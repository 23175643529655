import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";

const GetFishLog = (condition_for_pagination: string) => {
  return HTTP_CLIENT.get(Endpoint.fishLog.get + "?" + condition_for_pagination);
};

const EditFishLog = (id: string) => {
  return HTTP_CLIENT.get(`${Endpoint.fishLog.edit}/${id}`);
};

export { EditFishLog, GetFishLog };
