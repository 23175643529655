import React, { useEffect, useState } from "react";

interface InputProps {
  label: string;
  name: string;
  error: string | any;
  adjust: string;
  options: { id: number; value: string }[];
  setFieldValue: (name: string, value: any) => void;
  value: number | string;
  view_only: boolean;
}

const FormRadio = ({
  label,
  name,
  error,
  adjust,
  options,
  setFieldValue,
  value,
  view_only,
}: InputProps) => {
  const [selectedValue, setSelectedValue] = useState<number | string>(value);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedId = parseInt(event.target.value, 10);
    setSelectedValue(selectedId);
    setFieldValue(name, selectedId);
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <div className={adjust}>
      <label className="ms-1 mb-2">{label}</label>
      <br />

      {options.map((current_iteration) => (
        <div key={current_iteration.id}>
          <input
            type="radio"
            name={name}
            value={current_iteration.id}
            id={current_iteration.id.toString() + "-" + name}
            onChange={handleRadioChange}
            disabled={view_only}
            checked={selectedValue === current_iteration.id}
          />
          <label
            className="ms-2"
            htmlFor={current_iteration.id.toString() + "-" + name}
          >
            {current_iteration.value}
          </label>
          <br />
        </div>
      ))}

      {error && <span className="text-danger">{error}</span>}
      <br />
    </div>
  );
};

export default FormRadio;
