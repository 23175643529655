import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Form from "shared/components/form/form";
import showToast from "shared/components/toast/showToast";
import { routeConstant } from "shared/routes/routeConstant";
import { EditSavePoint } from "shared/services/savePointService";
import Validator from "shared/utils/validator";

const SavePointForm = () => {
  const { id } = useParams();
  const location = useLocation();
  const containsView = location.pathname.includes("view");
  const [formHeading, setFormHeading] = useState("");
  const [fieldsDefaultValues, setFieldsDefaultValues] = useState({
    id: "",
    type: "",
    image: "",
    point_name: "",
    description: "",
    latitude: "",
    longitude: "",
    date: "",
    time: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      EditSavePoint(id)
        .then(({ data: { status, data, message } }) => {
          if (status) {
            setFieldsDefaultValues({
              id: data.id,
              type: data.type ? "Shared" : "Self",
              image: data.base_url + data.image,
              point_name: data.point_name,
              description: data.description,
              latitude: data.latitude,
              longitude: data.longitude,
              date: data.date,
              time: data.time,
            });
          } else {
            showToast("error", message);
            navigate(routeConstant.private.dashboard.path);
          }
        })
        .catch((err) => {
          showToast("error", err?.response?.data?.message);
        });

      setFormHeading(routeConstant.private.viewSavePoint.title);
    }
  }, [id, containsView]);

  const formInputs = [
    {
      label: "ID",
      type: "text",
      placeholder: "ID",
      name: "id",
      adjust: "col-md-6",
    },
    {
      label: "Type",
      type: "text",
      placeholder: "Log Type",
      name: "type",
      adjust: "col-md-6",
    },
    {
      label: "Image",
      type: "file",
      placeholder: "Image",
      name: "image",
      adjust: "col-md-6",
    },
    {
      label: "Point Name",
      type: "text",
      placeholder: "Point Name",
      name: "point_name",
      adjust: "col-md-6",
    },
    {
      label: "Description",
      type: "textarea",
      placeholder: "Description",
      name: "description",
      adjust: "col-md-12",
      rows: 5,
    },
    {
      label: "Latitude",
      type: "text",
      placeholder: "Latitude",
      name: "latitude",
      adjust: "col-md-6",
    },
    {
      label: "Longitude",
      type: "text",
      placeholder: "Longitude",
      name: "longitude",
      adjust: "col-md-6",
    },
    {
      label: "Date",
      type: "text",
      placeholder: "Date",
      name: "date",
      adjust: "col-md-6",
    },
    {
      label: "Time",
      type: "text",
      placeholder: "Time",
      name: "time",
      adjust: "col-md-6",
    },
  ];

  return (
    <Form
      form_inputs={formInputs}
      fields_default_values={fieldsDefaultValues}
      validator={Validator.resourceSchema}
      form_heading={formHeading}
      view_only={containsView}
      form_id={id}
    />
  );
};

export default SavePointForm;
