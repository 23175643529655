import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import * as Icon from "react-feather";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LogoutPopUp from "shared/modals/logout/logout";
import { routeConstant } from "shared/routes/routeConstant";

const Header = ({ setToggle, toggle }: any) => {
  const { user } = useSelector((state: any) => state.ftb);
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <header className="navbar py-0 page-header navbar-expand navbar-light bg-white shadow-lg px-4">
        <ul className="navbar-nav d-flex align-items-center h-100">
          <li className="nav-item d-flex flex-column h-100 me-lg-2 align-items-center justify-content-center">
            <a
              href="javascript:void(0)"
              className="sidebar-trigger nav-link size-35 d-flex align-items-center justify-content-center p-0"
              onClick={() => setToggle(!toggle)}
            >
              <Icon.AlignJustify className="fe-1x align-middle" />
            </a>
          </li>
        </ul>
        <ul className="navbar-nav ms-auto d-flex align-items-center h-100">
          <li className="nav-item dropdown d-flex align-items-center justify-content-center flex-column h-100">
            <Dropdown>
              <Dropdown.Toggle
                style={{
                  backgroundColor: "transparent",
                  color: "black",
                  border: "none",
                  padding: "0px",
                  height: "22px",
                }}
              >
                <div className="d-flex align-items-center">
                  <div className="avatar-status status-online me-sm-2 avatar xs">
                    {user.user.profile_pic ? (
                      <img
                        src={user?.user?.base_url + user?.user?.profile_pic}
                        alt="profile_picture"
                        className="rounded-circle img-fluid"
                      />
                    ) : (
                      <img
                        src={user?.user?.base_url + "dummy.png"}
                        alt="profile_picture"
                        className="rounded-circle img-fluid"
                      />
                    )}
                  </div>
                  <span className="d-none d-md-inline-block">
                    {user?.user.first_name}
                  </span>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate(routeConstant.private.profile.path);
                  }}
                >
                  <Icon.User className="fe-2x me-2 p-0 me-3" />
                  Profile
                </Dropdown.Item>
                <Dropdown.Item className="p-0">
                  <LogoutPopUp />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </header>
    </>
  );
};

export default Header;
