import Input from "shared/components/input/input";
import { useFormik } from "formik";
import Validator from "shared/utils/validator";
import { SendOTPService } from "shared/services/authService";
import showToast from "shared/components/toast/showToast";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "shared/redux/reducers/userSlice";

interface ForgotProps {
  handleNavigation: (current: string, next: string) => void;
}

const Forgot = ({ handleNavigation }: ForgotProps) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.ftb);

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: { email: "" },
      validationSchema: Validator.forgotSchema,
      onSubmit: (values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
          SendOTPService(values).then(({ data: { status, data, message } }) => {
            if (status) {
              dispatch(
                setUser({
                  forgot: {email: values.email},
                })
              );
              showToast("success", message)
              handleNavigation("forgetPassword", "verification");
            } else {
              showToast("error", message);
            }
          })
          .catch((err) => {
            showToast("error", err?.response?.data?.message);
          });
        }, 400);
      },
    });

  return (
    <>
      <h4>Forgot Password!</h4>
      <p className="mb-4 text-muted">Enter your email to get OTP.</p>
      <form onSubmit={handleSubmit}>
        <div className="form-floating mb-3">
          <Input
            label="Email address"
            type="email"
            placeholder="name@example.com"
            value={values.email}
            onChange={handleChange("email")}
            onBlur={handleBlur}
            name="email"
            error={errors.email && touched.email && errors.email}
          />
        </div>
        <button className="w-100 btn btn-lg btn-primary rounded" type="submit">
          Send OTP
        </button>
      </form>
      <hr className="mt-4 mb-3" />
      <p className="text-muted mb-0">
        Remember your password?
        <span
          onClick={() => handleNavigation("forgetPassword", "welcome")}
          className="ms-2 text-dark cursor-pointer"
        >
          Sign In
        </span>
      </p>
    </>
  );
};

export default Forgot;
